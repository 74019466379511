<template>
  <span
      class="badge"
      :class="{
        'bg-light text-light': (errorReason === ' ') && !style,
        'bg-danger': (errorReason !== ' ') && !style,
        [style]: true,
        }"
      style="max-width: 384px;"
  >
    {{ errorReason }}
  </span>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ErrorLabel",
  computed: {
    ...mapState(['errorReason']),
  },
  props: {
    style: {
      type: [String, Boolean],
      default: false,
    },
  },
}
</script>

<style scoped>

</style>