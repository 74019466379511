<template>
  <div class="col-md-4 offset-md-4">
    <div class="login-form bg-light mt-4 p-4">
      <div class="row g-3">
        <h4>Регистрация успешно завершена</h4>
        <div class="col-12">
          <button type="submit" class="btn btn-dark float-end ms-3" @click="handleClickActionRegisterFinish">Продолжить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterSuccess",
  data() {
    return {
      email: '',
    }
  },
  emits: ['resubscribePage', 'switchMode'],
  methods: {
    handleClickActionRegisterFinish() {
      this.$store.dispatch('setWelcomeScreen', false); // TODO: Store it in the localStorage
      this.$emit('switchMode', 'login');
      this.$emit('resubscribePage');
    },
  },
}
</script>

<style scoped>

</style>