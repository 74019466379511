<template>
  <div class="col-md-4 offset-md-4">
    <div class="login-form bg-light mt-4 p-4">
      <div class="row g-3">
        <h4>Восстановление завершено</h4>
        <p>Установлен новый пароль</p>
        <div class="col-12">
          <button type="submit" class="btn btn-dark float-end ms-3" @click="handleClickActionRestoreFinish">Продолжить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {latestMessage} from "@/plugins/ws";

export default {
  name: "RestoreSuccess",
  data() {
    return {
      email: '',
      latestMessageValue: latestMessage,
    }
  },
  watch: {
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'auth-restore-set-password-finish':
          this.signalRestoreFinish({
            idUser: data.auth.idUser,
            name: data.auth.name,
            email: data.auth.email,
            admin: data.auth.admin,
            confirmEmail: data.auth.confirmEmail,
            register: data.auth.register,
            newPassword: data.auth.newPassword,
            restorePassword: data.auth.restorePassword,
          });
          break;
      }
    },
  },
  emits: ['resubscribePage', 'switchMode'],
  methods: {
    handleClickActionRestoreFinish() {
      this.$wsSend('authRestoreSetPasswordFinish');
      this.$emit('resubscribePage');
    },
    signalRestoreFinish(payload) {
      this.$store.dispatch('wsSetUser', payload);
      this.$emit('switchMode', 'login');
    },
  },
}
</script>

<style scoped>

</style>