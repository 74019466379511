<template>
  <div class="col-md-4 offset-md-4">
    <div class="login-form bg-light mt-4 p-4">
      <div class="row g-3">
        <h4>Подтвердите, что вы владелец восстанавливаемой почты</h4>
        <p>Вам на почту отправлено письмо с кодом, укажите пожалуйста тут этот код.</p>
        <p>Если что-то пошло не так, можно <a href="#" @click="handleClickActionRestoreResend" id="btn-resend">Отправить</a> повторно.</p>
        <div class="col-12 status-placeholder">
          <status-label :message="emailProgress" :style="emailProgressStyle"></status-label>
        </div>
        <div class="col-12">
          <label>Почта</label>
          <input type="email" name="email" class="form-control" placeholder="Email" v-model="restorePasswordEmail" readonly disabled>
        </div>
        <div class="col-12">
          <label>Код</label>
          <input type="text" name="code" class="form-control" placeholder="Code" v-model="code">
        </div>
        <div class="col-12 error-placeholder">
          <error-label/>
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-dark float-end ms-3" @click="handleClickActionRestoreConfirm" :disabled="disabledSubmit">Подтвердить</button>
          <button type="submit" class="btn btn-dark float-end" @click="handleClickActionRestoreConfirmCancel">Отмена</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorLabel from "@/components/ui/ErrorLabel.vue";
import {latestMessage} from "@/plugins/ws";
import {mapActions, mapState} from "vuex";
import StatusLabel from "@/components/ui/StatusLabel.vue";

export default {
  name: "RestoreCode",
  components: {
    StatusLabel,
    ErrorLabel,
  },
  data() {
    return {
      code: '',
      emailProgress: 'Prepare to sending ...',
      emailProgressStyle: 'bg-info',
      latestMessageValue: latestMessage,
      disabledSubmit: false,
    }
  },
  computed: {
    ...mapState(['user', 'restorePasswordEmail', 'errorReason']),
  },
  emits: ['switchMode'],
  watch: {
    email () {
      this.unsetErrorReason();
    },
    code () {
      this.unsetErrorReason();
    },
    latestMessageValue(data) {
      switch (data['signal']) {
        case 'auth-restore-email-progress':
          this.signalEmailProgress(data['message'], data['type']);
          break;
        case 'auth-restore-cancel':
          this.signalRestoreCancel();
          break;
        case 'auth-restore-resend':
          this.signalRestoreResend();
          break;
        case 'auth-restore-confirm':
          this.signalRestoreConfirm();
          break;
        case 'auth-restore-code-fail':
          this.signalRestoreCodeFail(data['reason']);
          break;
      }
    },
  },
  methods: {
    ...mapActions(['setErrorReason', 'unsetErrorReason']),
    handleClickActionRestoreResend() {
      this.$wsSend('authRestoreResend', {});
    },
    handleClickActionRestoreConfirm() {
      this.$wsSend('authRestoreCode', {
        email: this.restorePasswordEmail,
        code: this.code,
      });
      this.disabledSubmit = true;
    },
    handleClickActionRestoreConfirmCancel() {
      this.$wsSend('authRestoreCancel', {});
    },
    signalRestoreCodeFail(payload) {
      this.setErrorReason(payload);
      this.disabledSubmit = false;
    },
    signalRestoreCancel() {
      this.unsetErrorReason();
      this.disabledSubmit = false;
      this.$emit('switchMode', 'restore');
    },
    signalEmailProgress(message, type) {
      this.unsetErrorReason();
      this.emailProgress = message === null ? 'Prepare to sending ...' : message;
      switch (type) {
        case 'error':
          this.emailProgressStyle = 'bg-danger';
          break;
        case null:
        case 'info':
        default:
          this.emailProgressStyle = 'bg-info';
          break;
      }
    },
    signalRestoreResend() {
      this.user.restorePassword.emailProgressMessage = null;
      this.user.restorePassword.emailProgressType = null;
      this.emailProgress = 'Prepare to sending ...';
      this.emailProgressStyle = 'bg-info';
    },
    signalRestoreConfirm() {
      this.unsetErrorReason();
      this.disabledSubmit = false;
      this.$store.dispatch('wsRestoreConfirm');
      this.emailProgress = 'Prepare to sending ...';
      this.emailProgressStyle = 'bg-info';
      this.$emit('switchMode', 'restoreConfirm');
    },
  },
  mounted() {
    this.emailProgress = this.user.restorePassword.emailProgressMessage === null ? 'Prepare to sending ...' : this.user.restorePassword.emailProgressMessage;
    switch (this.user.emailProgressType) {
      case 'error':
        this.emailProgressStyle = 'bg-danger';
        break;
      case null:
      case 'info':
      default:
        this.emailProgressStyle = 'bg-info';
        break;
    }
  },
}
</script>

<style scoped>

</style>