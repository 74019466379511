import { ref } from 'vue';

let wsPort = null;
let connectOnDefinedPort = false;
let socket = null;
export const latestMessage = ref(null);
let delaySend = [];

function defaultOnOpen() {
  for (let i = 0; i < delaySend.length; i++) {
    socket.send(JSON.stringify(delaySend[i]));
  }
  delaySend = [];
}

function defaultOnClose(event) {
  console.log('Default webSocket on close handler:', event);
}

function defaultOnError(error) {
  console.log('Default webSocket on error handler:', error);
}

function defaultOnMessage(event) {
  latestMessage.value = JSON.parse(event.data);
}

let handlerOnOpen = defaultOnOpen;
let handlerOnClose = defaultOnClose;
let handlerOnError = defaultOnError;
let handlerOnMessage = defaultOnMessage;

function initWebSocket(host, port) {
  if ((socket instanceof WebSocket) && (socket.readyState === WebSocket.OPEN)) return;

  socket = new WebSocket(`wss://${host}:${port}/wss`);

  socket.addEventListener('open', handlerOnOpen);
  if (handlerOnOpen !== defaultOnOpen) {
    socket.addEventListener('open', defaultOnOpen);
  }
  socket.addEventListener('close', handlerOnClose);
  socket.addEventListener('error', handlerOnError);
  socket.addEventListener('message', handlerOnMessage);
  if (handlerOnMessage !== defaultOnMessage) {
    socket.addEventListener('message', defaultOnMessage);
  }
}

function setOnOpen(onOpen) {
  if (socket) {
    socket.removeEventListener('open', handlerOnOpen);
    if (handlerOnOpen !== defaultOnOpen) {
      socket.removeEventListener('open', defaultOnOpen);
    }
  }
  handlerOnOpen = onOpen;
  if (socket) {
    socket.addEventListener('open', handlerOnOpen);
    if (handlerOnOpen !== defaultOnOpen) {
      socket.addEventListener('open', defaultOnOpen);
    }
  }
}

function setOnClose(onClose) {
  if (socket) {
    socket.removeEventListener('close', handlerOnClose);
  }
  handlerOnClose = onClose;
  if (socket) {
    socket.addEventListener('close', handlerOnClose);
  }
}

function setOnError(onError) {
  if (socket) {
    socket.removeEventListener('error', handlerOnError);
  }
  handlerOnError = onError;
  if (socket) {
    socket.addEventListener('error', handlerOnError);
  }
}

function setOnMessage(onMessage) {
  if (socket) {
    socket.removeEventListener('message', handlerOnMessage);
    if (handlerOnMessage !== defaultOnMessage) {
      socket.removeEventListener('message', defaultOnMessage);
    }
  }
  handlerOnMessage = onMessage;
  if (socket) {
    socket.addEventListener('message', handlerOnMessage);
    if (handlerOnMessage !== defaultOnMessage) {
      socket.addEventListener('message', defaultOnMessage);
    }
  }
}

export const WebSocketPlugin = {
  install(app) {
    fetch("/config.json")
      .then(response => response.json())
      .then(json => {
        wsPort = json['wsPort'];
        if (connectOnDefinedPort) {
          initWebSocket(window.location.hostname, wsPort);
          connectOnDefinedPort = false;
        }
      })
      .catch(error => {
        console.error("Error fetching config:", error);
      });

    app.config.globalProperties.$wsReConnect = () => {
      if ((socket instanceof WebSocket) && (socket.readyState !== WebSocket.CLOSED)) {
        console.log('Closing old webSocket connection', socket);
        socket.close();
      }
      if (wsPort !== null) {
        initWebSocket(window.location.hostname, wsPort);
      } else {
        connectOnDefinedPort = true;
      }
    }

    app.config.globalProperties.$wsSend = (action, params) => {
      let data = {
        action: action,
        data: params,
      }
      if ((socket instanceof WebSocket) && (socket.readyState === WebSocket.OPEN)) {
        socket.send(JSON.stringify(data));
      } else {
        delaySend.push(data);
      }
    };

    app.config.globalProperties.$wsSetOnOpen = (onOpen) => {
      setOnOpen(onOpen);
    };
    app.config.globalProperties.$wsSetOnClose = (onClose) => {
      setOnClose(onClose);
    };
    app.config.globalProperties.$wsSetOnError = (onError) => {
      setOnError(onError);
    };
    app.config.globalProperties.$wsSetOnMessage = (onMessage) => {
      setOnMessage(onMessage);
    };
  }
};