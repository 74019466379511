<template>
  <span
      class="badge"
      :class="{
        'bg-light text-light': message === ' ' && !style,
        [style]: true,
        }"
  >
    {{ message }}
  </span>
</template>

<script>

export default {
  name: "StatusLabel",
  props: {
    style: {
      type: [String, Boolean],
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>

</style>