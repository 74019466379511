import {createStore} from 'vuex'

export default createStore({
  state(){
    return {
      wsConnected: false,
      wsLoaded: false,
      wsSession: '',
      theme: 'auto', // 'light', 'dark', 'auto', null
      themeDoNotShow: false,
      authMode: null,
      errorReason: ' ',
      errorStatus: 'error', // 'error', 'success'
      user: {
        /**
         * 0 -- не инициализированная сессия
         * null -- пользователь "гость"
         * >0 -- авторизованный пользователь
         */
        idUser: 0,
        email: '',
        name: '',
        theme: null,
        admin: false,
        confirmEmail: false,
      },
      welcomeScreen: false,
      restorePasswordEmail: '',
      currency: {
        idCurrency: null,
        code: '',
        blockchains: [],
        locked: false,
      },
      language: {
        idLanguage: null,
        code: '',
        selfTitle: '',
        rtl: '',
        locked: false,
      },
      country: {
        idCountry: null,
        code: '',
        locked: false,
      },
      exchange: {
        idExchange: null,
        code: '',
        locked: false,
        blockStore: false,
        blockStream: false,
      },
      indicator: {
        idIndicator: null,
        name: '',
        locked: false,
      },
      exchangePair: {
        idExchangePair: null,
        code: '',
        locked: false,
      },
      priceExchange: {
        indexExchange: null,
        code: '',
      },
      globalPair: {
        idGlobalPair: null,
        idCurrency: null,
        idGlobalCurrency: null,
        showOnMainPage: false,
        locked: false,
        timeStart: null,
        lastCandleTimes: [],
        lastAiCandleDiffTimes: [],
        lastIndicatorTimes: [],
        lastAiIndicatorTimes: [],
      },
      selected: {
        idLanguage: null,
        idLocale: null,
        idBlockchain: null,
        idCurrency: null,
        idCurrencySecondary: null,
        idCountry: null,
        idExchange: null,
        idGlobalPair: null,
        idGlobalCurrency: null,
        idIndicator: null,
        idIndicatorParam: null,
        idIndicatorOut: null,
        idIndicatorSet: null,
        idSetting: null,
        idUserWallet: null,
        timeBackup: null,
        timeFrame: null,
        indexCurrency: null,
        indexExchange: null,
        indexPair: null,
        candleCount: null,
      },
      indicators: [],
      indicatorParams: [],
      indicatorOuts: [],
      indicatorSetParams: [],
      indicatorSets: [],
      globalPairIndicatorSets: [],
      globalPairExchanges: [],
      currencies: [],
      currencyBlockchains: [],
      countries: [],
      countryLanguages: [],
      blockchains: [],
      globalCurrencies: [],
      globalPairs: [],
      exchanges: [],
      exchangeCurrencies: [],
      exchangePairs: [],
      languages: [],
      locales: [],
      settings: [],
      tradeGroups: [],
      priceCurrencies: [],
      priceExchanges: [],
      pricePairs: [],
      userWallets: [],
      backups: [],
      mathSettings: {
        math: [],
        types: [],
      },
    }
  },
  getters: {
    isSessionInit(state) {
      return state.user.idUser !== 0;
    },
    isUserEmailConfirm(state) {
      return state.user.confirmEmail && !state.welcomeScreen;
    },
    getGlobalPairCode(state) {
      if (typeof state.currencies.find == 'undefined') {
        return 'null';
      }

      let currency = state.currencies.find(item => item.idCurrency === state.globalPair.idCurrency);

      if (typeof currency == 'undefined') {
        return 'null';
      }

      if (typeof state.globalCurrencies.find == 'undefined') {
        return 'null';
      }

      let globalCurrency = state.globalCurrencies.find(item => item.idGlobalCurrency === state.globalPair.idGlobalCurrency);

      if (typeof globalCurrency == 'undefined') {
        return 'null';
      }

      let currencyGlobalCurrency = state.currencies.find(item => item.idCurrency === globalCurrency.idCurrency);

      return currency.code + '-' + currencyGlobalCurrency.code;
    },
    getSelectedCurrencyCode(state) {
      if (state.selected.idCurrency === null) {
        return 'null';
      }

      if (state.currency.idCurrency === state.selected.idCurrency) {
        return state.currency.code;
      }

      let currency = state.currencies.find(currency => currency.idCurrency === state.selected.idCurrency);
      if (typeof currency == 'undefined') {
        return 'null';
      }

      return currency.code;
    },
    getSelectedCountryCode(state) {
      if (state.selected.idCountry === null) {
        return 'null';
      }

      if (state.country.idCountry === state.selected.idCountry) {
        return state.country.code;
      }

      let country = state.countries.find(country => country.idCountry === state.selected.idCountry);
      if (typeof country == 'undefined') {
        return 'null';
      }

      return country.code;
    },
    getSelectedCurrencySecondaryCode(state) {
      if (state.selected.idCurrencySecondary === null) {
        return 'null';
      }

      let currency = state.currencies.find(currency => currency.idCurrency === state.selected.idCurrencySecondary);

      if (typeof currency == 'undefined') {
        return 'null';
      }

      return currency.code;
    },
    getSelectedGlobalCurrencyCode(state) {
      if (state.selected.idGlobalCurrency === null) {
        return 'null';
      }

      let globalCurrency = state.globalCurrencies.find(globalCurrency => globalCurrency.idGlobalCurrency === state.selected.idGlobalCurrency)
      if (!globalCurrency) {
        return 'null';
      }

      let currency = state.currencies.find(currency => globalCurrency.idCurrency === currency.idCurrency);
      if (!currency) {
        return 'null';
      }

      return currency.code;
    },
    getSelectedLanguageCode(state) {
      if (state.selected.idLanguage === null) {
        return 'null';
      }

      let language = state.languages.find(language => language.idLanguage === state.selected.idLanguage);

      if (typeof language == 'undefined') {
        return 'null';
      }

      return language.code;
    },
    getSelectedLanguageRTL(state) {
      if (state.selected.idLanguage === null) {
        return 'null';
      }

      let language = state.languages.find(language => language.idLanguage === state.selected.idLanguage);

      if (typeof language == 'undefined') {
        return 'null';
      }

      return language.rtl;
    },
    getSelectedLanguageSelfTitle(state) {
      if (state.selected.idLanguage === null) {
        return 'null';
      }

      let language = state.languages.find(language => language.idLanguage === state.selected.idLanguage);

      if (typeof language == 'undefined') {
        return 'null';
      }

      return language.selfTitle;
    },
    getSelectedBlockchainName(state) {
      if (state.selected.idBlockchain === null) {
        return 'null';
      }

      let blockchain = state.blockchains.find(blockchain => blockchain.idBlockchain === state.selected.idBlockchain);

      if (typeof blockchain == 'undefined') {
        return 'null';
      }

      return blockchain.name;
    },
    getSelectedBlockchainPreg(state) {
      if (state.selected.idBlockchain === null) {
        return 'null';
      }

      let blockchain = state.blockchains.find(blockchain => blockchain.idBlockchain === state.selected.idBlockchain);

      if (typeof blockchain == 'undefined') {
        return 'null';
      }

      return blockchain.preg;
    },
    getSelectedBlockchainTag(state) {
      if (state.selected.idBlockchain === null) {
        return 'null';
      }

      let blockchain = state.blockchains.find(blockchain => blockchain.idBlockchain === state.selected.idBlockchain);

      if (typeof blockchain == 'undefined') {
        return 'null';
      }

      return blockchain.tag;
    },
    getSelectedSettingType(state) {
      if (state.selected.idSetting === null) {
        return 'null';
      }

      let setting = state.settings.find(setting => setting.idSetting === state.selected.idSetting);

      if (typeof setting == 'undefined') {
        return 'null';
      }

      return setting.type;
    },
    getSelectedSettingName(state) {
      if (state.selected.idSetting === null) {
        return 'null';
      }

      let setting = state.settings.find(setting => setting.idSetting === state.selected.idSetting);

      if (typeof setting == 'undefined') {
        return 'null';
      }

      return setting.name;
    },
    getSelectedTradeGroupName(state) {
      if (state.selected.idTradeGroup === null) {
        return 'null';
      }

      let tradeGroup = state.tradeGroups.find(tradeGroup => tradeGroup.idTradeGroup === state.selected.idTradeGroup);

      if (typeof tradeGroup == 'undefined') {
        return 'null';
      }

      return tradeGroup.name;
    },
    getSelectedTradeGroupTelegram(state) {
      if (state.selected.idTradeGroup === null) {
        return 'null';
      }

      let tradeGroup = state.tradeGroups.find(tradeGroup => tradeGroup.idTradeGroup === state.selected.idTradeGroup);

      if (typeof tradeGroup == 'undefined') {
        return 'null';
      }

      return tradeGroup.telegram;
    },
    getSelectedTradeGroupTimeframes(state) {
      if (state.selected.idTradeGroup === null) {
        return 'null';
      }

      let tradeGroup = state.tradeGroups.find(tradeGroup => tradeGroup.idTradeGroup === state.selected.idTradeGroup);

      if (typeof tradeGroup == 'undefined') {
        return 'null';
      }

      return tradeGroup.timeframes;
    },
    getSelectedIndicatorName(state) {
      if (state.selected.idIndicator === null) {
        return 'null';
      }

      let indicator = state.indicators.find(indicator => indicator.idIndicator === state.selected.idIndicator);

      if (typeof indicator == 'undefined') {
        return 'null';
      }

      return indicator.name;
    },
    getSelectedExchangeCode(state) {
      if (state.selected.idExchange === null) {
        return 'null';
      }

      let exchange = state.exchanges.find(exchange => exchange.idExchange === state.selected.idExchange);

      if (typeof exchange == 'undefined') {
        return 'null';
      }

      return exchange.code;
    },
    getSelectedExchangeBlockStore(state) {
      if (state.selected.idExchange === null) {
        return 'null';
      }

      let exchange = state.exchanges.find(exchange => exchange.idExchange === state.selected.idExchange);

      if (typeof exchange == 'undefined') {
        return 'null';
      }

      return exchange.blockStore;
    },
    getSelectedExchangeBlockStream(state) {
      if (state.selected.idExchange === null) {
        return 'null';
      }

      let exchange = state.exchanges.find(exchange => exchange.idExchange === state.selected.idExchange);

      if (typeof exchange == 'undefined') {
        return 'null';
      }

      return exchange.blockStream;
    },
    getSelectedBackupName(state) {
      if (state.selected.timeBackup === null) {
        return 'null';
      }

      let backup = state.backups.find(backup => backup.time === state.selected.timeBackup);

      if (typeof backup == 'undefined') {
        return 'null';
      }

      let formatDateTime = (timestamp) => {
        const date = new Date(timestamp);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
      };

      // TODO: map backup.type
      return backup.dir + ' | ' + formatDateTime(backup.time) + ' | ' + backup.name + ' | ' + backup.type + ' | ';
    },
    getSelectedLanguageName(state) {
      if (state.selected.idLanguage === null) {
        return 'null';
      }

      let language = state.languages.find(language => language.idLanguage === state.selected.idLanguage);

      if (typeof language == 'undefined') {
        return 'null';
      }

      return language.name;
    },
  },
  mutations: {
    setAuthMode(state, payload) {
      if (['login', 'register', 'registerCode', 'registerSuccess', 'restore', 'restoreCode', 'restoreConfirm', 'restoreSuccess', null].includes(payload)) {
        state.authMode = payload;
      } else {
        state.authMode = null;
      }

      let modeStored;
      switch (state.authMode) {
        case null:
          modeStored = null;
          break;
        case 'login':
          modeStored = 'login';
          break;
        case 'registerCode':
        case 'registerSuccess':
        case 'register':
          modeStored = 'register';
          break;
        case 'restoreCode':
        case 'restoreConfirm':
        case 'restoreSuccess':
        case 'restore':
          modeStored = 'restore';
          break;
        default:
          modeStored = null;
          break;
      }
      switch (state.authMode) {
        case 'register':
          if ((!state.user.confirmEmail) && (state.user.register && state.user.register.success !== null)) {
            state.authMode = 'registerCode';
          } else if (state.welcomeScreen) {
            state.authMode = 'registerSuccess';
          }
          break;
        case 'restore':
          if ((state.user.idUser === null) && (state.user.email !== null)) {
            if (!state.user.restorePassword.confirm) {
              state.authMode = 'registerCode';
            } else if (state.user.restorePassword.success === null) {
              state.authMode = 'restoreConfirm';
            } else {
              state.authMode = 'restoreSuccess';
            }
          }
          break;
      }

      if (!['login', 'register', 'restore', null].includes(modeStored)) {
        modeStored = null;
      }
      if (modeStored === null) {
        localStorage.removeItem('authMode');
      } else if (localStorage.getItem('authMode') !== modeStored) {
        localStorage.setItem('authMode', modeStored);
      }
    },
    setTheme(state, payload) {
      if (['light', 'dark', 'auto', null].includes(payload)) {
        state.theme = payload;
      } else {
        state.theme = null;
      }

      if (state.theme === null) {
        localStorage.removeItem('bs-theme');
      } else if (localStorage.getItem('bs-theme') !== state.theme) {
        localStorage.setItem('bs-theme', state.theme);
      }
    },
    setThemeDoNotShow(state, payload) {
      state.themeDoNotShow = payload;
      if (payload) {
        localStorage.setItem('bs-theme-do-not-show', 'true');
      } else {
        localStorage.removeItem('bs-theme-do-not-show');
      }
    },
    setErrorReason(state, payload) {
      state.errorReason = payload;
    },
    unsetErrorReason(state) {
      state.errorReason = ' ';
      state.errorStatus = 'error';
    },
    setErrorStatus(state, payload) {
      state.errorStatus = payload;
    },
    unselectAll(state) {
      state.selected.idLanguage = null;
      state.selected.idLocale = null;
      state.selected.idBlockchain = null;
      state.selected.idCurrency = null;
      state.selected.idCurrencySecondary = null;
      state.selected.idCountry = null;
      state.selected.idExchange = null;
      state.selected.idGlobalPair = null;
      state.selected.idGlobalCurrency = null;
      state.selected.idIndicator = null;
      state.selected.idIndicatorParam = null;
      state.selected.idIndicatorSet = null;
      state.selected.idSetting = null;
      state.selected.idTradeGroup = null;
      state.selected.timeBackup = null;
      state.selected.timeFrame = null;
      state.selected.indexCurrency = null;
      state.selected.indexExchange = null;
      state.selected.indexPair = null;
      state.selected.candleCount = null;
    },
    selectLanguage(state, payload) {
      state.selected.idLanguage = payload;
    },
    selectLocale(state, payload) {
      state.selected.idLocale = payload;
    },
    selectBlockchain(state, payload) {
      state.selected.idBlockchain = payload;
    },
    selectCurrency(state, payload) {
      state.selected.idCurrency = payload;
    },
    selectCurrencySecondary(state, payload) {
      state.selected.idCurrencySecondary = payload;
    },
    selectCountry(state, payload) {
      state.selected.idCountry = payload;
    },
    selectExchange(state, payload) {
      state.selected.idExchange = payload;
    },
    selectGlobalPair(state, payload) {
      state.selected.idGlobalPair = payload;
    },
    selectGlobalCurrency(state, payload) {
      state.selected.idGlobalCurrency = payload;
    },
    selectIndicator(state, payload) {
      state.selected.idIndicator = payload;
    },
    selectIndicatorParam(state, payload) {
      state.selected.idIndicatorParam = payload;
    },
    selectIndicatorOut(state, payload) {
      state.selected.idIndicatorOut = payload;
    },
    selectIndicatorSet(state, payload) {
      state.selected.idIndicatorSet = payload;
    },
    selectSetting(state, payload) {
      state.selected.idSetting = payload;
    },
    selectTradeGroup(state, payload) {
      state.selected.idTradeGroup = payload;
    },
    selectUserWallet(state, payload) {
      state.selected.idUserWallet = payload;
    },
    selectBackup(state, payload) {
      state.selected.timeBackup = payload;
    },
    selectTimeFrame(state, payload) {
      state.selected.timeFrame = payload;
      localStorage.setItem('selectedTimeframe', payload);
    },
    selectCandleCount(state, payload) {
      state.selected.candleCount = payload;
      localStorage.setItem('selectedCandleCount', payload);
    },
    selectPriceCurrency(state, payload) {
      state.selected.indexCurrency = payload;
    },
    selectPriceExchange(state, payload) {
      state.selected.indexExchange = payload;
    },
    selectPricePair(state, payload) {
      state.selected.indexPair = payload;
    },
    setUser(state, payload) {
      state.user.idUser = payload.idUser;
      state.user.email = payload.email;
      state.user.name = payload.name;
      state.user.theme = payload.theme;
      state.user.admin = payload.admin;
      state.user.confirmEmail = payload.confirmEmail;

      state.user.register = payload.register;
      state.user.newPassword = payload.newPassword;
      state.user.restorePassword = payload.restorePassword;
      state.user.changeEmail = payload.changeEmail;
    },
    unsetUser(state) {
      state.user.idUser = null;
      state.user.email = '';
      state.user.name = '';
      state.user.admin = false;
      state.user.confirmEmail = false;

      state.user.register = {
        emailProgressMessage: null,
        emailProgressType: null,
        success: null,
      };
      state.user.newPassword = {
        emailProgressMessage: null,
        emailProgressType: null,
        code: null,
        success: null,
      };
      state.user.restorePassword = {
        emailProgressMessage: null,
        emailProgressType: null,
        confirm: false,
        success: null,
      };
      state.user.changeEmail = {
        emailProgressMessage: null,
        emailProgressType: null,
        changeEmail: null,
        success: null,
      };
    },
    setWelcomeScreen(state, payload) {
      state.welcomeScreen = payload;
    },
    setLoaded(state, payload) {
      state.wsLoaded = payload;
    },
    profileSetName(state, payload) {
      state.user.name = payload;
    },
    profileSetTheme(state, payload) {
      state.user.theme = payload;
    },
    setExchange(state, payload) {
      state.exchange.idExchange = payload.idExchange;
      state.exchange.code = payload.code;
      state.exchange.locked = payload.locked;
      state.exchange.blockStore = payload.blockStore;
      state.exchange.blockStream = payload.blockStream;
    },
    setExchangePair(state, payload) {
      state.exchangePair.idExchangePair = payload.idExchangePair;
      state.exchangePair.code = payload.code;
      state.exchangePair.locked = payload.locked;
    },
    setGlobalPair(state, payload) {
      state.globalPair.idGlobalPair = payload.idGlobalPair;
      state.globalPair.idCurrency = payload.idCurrency;
      state.globalPair.idGlobalCurrency = payload.idGlobalCurrency;
      state.globalPair.showOnMainPage = payload.showOnMainPage;
      state.globalPair.locked = payload.locked;

      state.globalPair.timeStart = payload.timeStart;

      state.globalPair.lastCandleTimes = [];
      Object.keys(payload.lastCandleTimes).forEach(timeframeIndex => {
        state.globalPair.lastCandleTimes.push({
          'timeframe': timeframeIndex,
          'lastCandleTime': payload.lastCandleTimes[timeframeIndex],
        });
      });

      state.globalPair.lastAiCandleDiffTimes = [];
      Object.keys(payload.lastAiCandleDiffTimes).forEach(timeframeIndex => {
        state.globalPair.lastAiCandleDiffTimes.push({
          'timeframe': timeframeIndex,
          'lastAiCandleDiffTime': payload.lastAiCandleDiffTimes[timeframeIndex],
        });
      });

      state.globalPair.lastIndicatorTimes = [];
      Object.keys(payload.lastIndicatorTimes).forEach(indicatorSetIndex => {
        let timeframes = payload.lastIndicatorTimes[indicatorSetIndex];
        state.globalPair.lastIndicatorTimes.push({
          'idIndicatorSet': parseInt(indicatorSetIndex),
          'timeframes': [],
        });
        let lastIndicatorTime = state.globalPair.lastIndicatorTimes.find(lastIndicatorTime => lastIndicatorTime.idIndicatorSet === parseInt(indicatorSetIndex));
        Object.keys(timeframes).forEach(timeframeIndex => {
          lastIndicatorTime.timeframes.push({
            'timeframe': timeframeIndex,
            'lastIndicatorTime': timeframes[timeframeIndex],
          });
        });
      });

      state.globalPair.lastAiIndicatorTimes = [];
      Object.keys(payload.lastAiIndicatorTimes).forEach(aiIndicatorSetIndex => {
        let timeframes = payload.lastAiIndicatorTimes[aiIndicatorSetIndex];
        state.globalPair.lastAiIndicatorTimes.push({
          'idIndicatorSet': parseInt(aiIndicatorSetIndex),
          'timeframes': [],
        });
        let lastAiIndicatorTime = state.globalPair.lastAiIndicatorTimes.find(lastAiIndicatorTime => lastAiIndicatorTime.idIndicatorSet === parseInt(aiIndicatorSetIndex));
        Object.keys(timeframes).forEach(timeframeIndex => {
          lastAiIndicatorTime.timeframes.push({
            'timeframe': timeframeIndex,
            'lastAiIndicatorTime': timeframes[timeframeIndex],
          });
        });
      });
    },
    setGlobalPairCalcUpdate(state, payload) {
      let stateGlobalPair;

      if (typeof payload.candlesLast != 'undefined') {
        Object.keys(payload.candlesLast).forEach(globalPairIndex => {
          let globalPariData = payload.candlesLast[globalPairIndex];
          stateGlobalPair = state.globalPairs.find(globalPair => globalPair.idGlobalPair === parseInt(globalPairIndex));
          Object.keys(globalPariData).forEach(timeframeIndex => {
            let lastCandleTime = stateGlobalPair.lastCandleTimes.find(lastCandleTime => lastCandleTime.timeframe === timeframeIndex);
            if (typeof lastCandleTime == 'undefined') {
              stateGlobalPair.lastCandleTimes.push({
                'timeframe': timeframeIndex,
                'lastCandleTime': globalPariData[timeframeIndex],
              });
            } else {
              lastCandleTime['lastCandleTime'] = globalPariData[timeframeIndex];
            }
          });
        });
      }

      if (typeof payload.aiCandlesDiffLast != 'undefined') {
        Object.keys(payload.aiCandlesDiffLast).forEach(globalPairIndex => {
          let globalPariData = payload.aiCandlesDiffLast[globalPairIndex];
          stateGlobalPair = state.globalPairs.find(globalPair => globalPair.idGlobalPair === parseInt(globalPairIndex));
          Object.keys(globalPariData).forEach(timeframeIndex => {
            let lastAiCandleDiffTime = stateGlobalPair.lastAiCandleDiffTimes.find(lastAiCandleDiffTime => lastAiCandleDiffTime.timeframe === timeframeIndex);
            if (typeof lastAiCandleDiffTime == 'undefined') {
              stateGlobalPair.lastAiCandleDiffTimes.push({
                'timeframe': timeframeIndex,
                'lastAiCandleDiffTime': globalPariData[timeframeIndex],
              });
            } else {
              lastAiCandleDiffTime['lastAiCandleDiffTime'] = globalPariData[timeframeIndex];
            }
          });
        });
      }

      if (typeof payload.indicatorsLast != 'undefined') {
        Object.keys(payload.indicatorsLast).forEach(globalPairIndex => {
          let globalPariData = payload.indicatorsLast[globalPairIndex];
          stateGlobalPair = state.globalPairs.find(globalPair => globalPair.idGlobalPair === parseInt(globalPairIndex));
          Object.keys(globalPariData).forEach(indicatorSetIndex => {
            let timeframes = globalPariData[indicatorSetIndex];
            let lastIndicatorTime = stateGlobalPair.lastIndicatorTimes.find(lastIndicatorTime => lastIndicatorTime.idIndicatorSet === parseInt(indicatorSetIndex));
            if (typeof lastIndicatorTime == 'undefined') {
              stateGlobalPair.lastIndicatorTimes.push({
                'idIndicatorSet': parseInt(indicatorSetIndex),
                'timeframes': [],
              });
              lastIndicatorTime = stateGlobalPair.lastIndicatorTimes.find(lastIndicatorTime => lastIndicatorTime.idIndicatorSet === parseInt(indicatorSetIndex));
            }
            Object.keys(timeframes).forEach(timeframeIndex => {
              let timeframe = lastIndicatorTime.timeframes.find(timeframe => timeframe['timeframe'] === timeframeIndex);
              if (typeof timeframe == 'undefined') {
                lastIndicatorTime.timeframes.push({
                  'timeframe': timeframeIndex,
                  'lastIndicatorTime': timeframes[timeframeIndex],
                });
              } else {
                timeframe.lastIndicatorTime = timeframes[timeframeIndex];
              }
            });
          });
        });
      }

      if (typeof payload.aiIndicatorsLast != 'undefined') {
        Object.keys(payload.aiIndicatorsLast).forEach(globalPairIndex => {
          let globalPariData = payload.aiIndicatorsLast[globalPairIndex];
          stateGlobalPair = state.globalPairs.find(globalPair => globalPair.idGlobalPair === parseInt(globalPairIndex));
          Object.keys(globalPariData).forEach(aiIndicatorSetIndex => {
            let timeframes = globalPariData[aiIndicatorSetIndex];
            let lastAiIndicatorTime = stateGlobalPair.lastAiIndicatorTimes.find(lastAiIndicatorTime => lastAiIndicatorTime.idIndicatorSet === parseInt(aiIndicatorSetIndex));
            if (typeof lastAiIndicatorTime == 'undefined') {
              stateGlobalPair.lastAiIndicatorTimes.push({
                'idIndicatorSet': parseInt(aiIndicatorSetIndex),
                'timeframes': [],
              });
              lastAiIndicatorTime = stateGlobalPair.lastAiIndicatorTimes.find(lastAiIndicatorTime => lastAiIndicatorTime.idIndicatorSet === parseInt(aiIndicatorSetIndex));
            }
            Object.keys(timeframes).forEach(timeframeIndex => {
              let timeframe = lastAiIndicatorTime.timeframes.find(timeframe => timeframe['timeframe'] === timeframeIndex);
              if (typeof timeframe == 'undefined') {
                lastAiIndicatorTime.timeframes.push({
                  'timeframe': timeframeIndex,
                  'lastAiIndicatorTime': timeframes[timeframeIndex],
                });
              } else {
                timeframe.lastAiIndicatorTime = timeframes[timeframeIndex];
              }
            });
          });
        });
      }
    },
    setGlobalPairDataUpdate(state, payload) {
      let updateGlobalPairLastTimes = function (stateGlobalPair, payload) {
        if (typeof payload.candlesLast != 'undefined') {
          Object.keys(payload.candlesLast).forEach(timeframeIndex => {
            let lastCandleTime = stateGlobalPair.lastCandleTimes.find(lastCandleTime => lastCandleTime.timeframe === timeframeIndex);
            if (typeof lastCandleTime == 'undefined') {
              stateGlobalPair.lastCandleTimes.push({
                'timeframe': timeframeIndex,
                'lastCandleTime': payload.candlesLast[timeframeIndex],
              });
            } else {
              lastCandleTime['lastCandleTime'] = payload.candlesLast[timeframeIndex];
            }
          });
        }

        if (typeof payload.aiCandlesDiffLast != 'undefined') {
          Object.keys(payload.aiCandlesDiffLast).forEach(timeframeIndex => {
            let lastAiCandleDiffTime = stateGlobalPair.lastAiCandleDiffTimes.find(lastAiCandleDiffTime => lastAiCandleDiffTime.timeframe === timeframeIndex);
            if (typeof lastAiCandleDiffTime == 'undefined') {
              stateGlobalPair.lastAiCandleDiffTimes.push({
                'timeframe': timeframeIndex,
                'lastAiCandleDiffTime': payload.aiCandlesDiffLast[timeframeIndex],
              });
            } else {
              lastAiCandleDiffTime['lastAiCandleDiffTime'] = payload.aiCandlesDiffLast[timeframeIndex];
            }
          });
        }

        if (typeof payload.indicatorsLast != 'undefined') {
          Object.keys(payload.indicatorsLast).forEach(indicatorSetIndex => {
            let timeframes = payload.indicatorsLast[indicatorSetIndex];
            let lastIndicatorTime = stateGlobalPair.lastIndicatorTimes.find(lastIndicatorTime => lastIndicatorTime.idIndicatorSet === parseInt(indicatorSetIndex));
            if (typeof lastIndicatorTime == 'undefined') {
              stateGlobalPair.lastIndicatorTimes.push({
                'idIndicatorSet': parseInt(indicatorSetIndex),
                'timeframes': [],
              });
              lastIndicatorTime = stateGlobalPair.lastIndicatorTimes.find(lastIndicatorTime => lastIndicatorTime.idIndicatorSet === parseInt(indicatorSetIndex));
            }
            Object.keys(timeframes).forEach(timeframeIndex => {
              let timeframe = lastIndicatorTime.timeframes.find(timeframe => timeframe['timeframe'] === timeframeIndex);
              if (typeof timeframe == 'undefined') {
                lastIndicatorTime.timeframes.push({
                  'timeframe': timeframeIndex,
                  'lastIndicatorTime': timeframes[timeframeIndex],
                });
              } else {
                timeframe.lastIndicatorTime = timeframes[timeframeIndex];
              }
            });
          });
        }

        if (typeof payload.aiIndicatorsLast != 'undefined') {
          Object.keys(payload.aiIndicatorsLast).forEach(aiIndicatorSetIndex => {
            let timeframes = payload.aiIndicatorsLast[aiIndicatorSetIndex];
            let lastAiIndicatorTime = stateGlobalPair.lastAiIndicatorTimes.find(lastAiIndicatorTime => lastAiIndicatorTime.idIndicatorSet === parseInt(aiIndicatorSetIndex));
            if (typeof lastAiIndicatorTime == 'undefined') {
              stateGlobalPair.lastAiIndicatorTimes.push({
                'idIndicatorSet': parseInt(aiIndicatorSetIndex),
                'timeframes': [],
              });
              lastAiIndicatorTime = stateGlobalPair.lastAiIndicatorTimes.find(lastAiIndicatorTime => lastAiIndicatorTime.idIndicatorSet === parseInt(aiIndicatorSetIndex));
            }
            Object.keys(timeframes).forEach(timeframeIndex => {
              let timeframe = lastAiIndicatorTime.timeframes.find(timeframe => timeframe['timeframe'] === timeframeIndex);
              if (typeof timeframe == 'undefined') {
                lastAiIndicatorTime.timeframes.push({
                  'timeframe': timeframeIndex,
                  'lastAiIndicatorTime': timeframes[timeframeIndex],
                });
              } else {
                timeframe.lastAiIndicatorTime = timeframes[timeframeIndex];
              }
            });
          });
        }
      };

      if (state.globalPair.idGlobalPair !== null && payload.idGlobalPair === state.globalPair.idGlobalPair) {
        updateGlobalPairLastTimes(state.globalPair, payload);
      }

      let globalPair = state.globalPairs.find(globalPair => globalPair.idGlobalPair === payload.idGlobalPair);
      if (typeof globalPair != 'undefined') {
        updateGlobalPairLastTimes(globalPair, payload);
      }
    },
    setCurrency(state, payload) {
      state.currency.idCurrency = payload.idCurrency;
      state.currency.code = payload.code;
      state.currency.locked = payload.locked;
      state.currency.blockchains = [];
      Object.keys(payload.blockchains).forEach(blockchainIndex => {
        let currency = payload.blockchains[blockchainIndex];
        state.currency.blockchains.push({
          idCurrency: currency.idCurrency,
          idBlockchain: currency.idBlockchain,
          main: currency.main,
          contract: currency.contract,
          locked: currency.locked,
        });
      });
    },
    setGlobalPairs(state, payload) {
      state.globalPairs = [];
      Object.keys(payload.globalPairs).forEach(globalPairIndex => {
        let globalPair = payload.globalPairs[globalPairIndex];

        state.globalPairs.push({
          idGlobalPair: globalPair.idGlobalPair,
          idGlobalCurrency: globalPair.idGlobalCurrency,
          idCurrency: globalPair.idCurrency,
          showOnMainPage: globalPair.showOnMainPage,
          locked: globalPair.locked,
          offset: globalPair.offset,
          timeStart: globalPair.timeStart,

          lastCandleTimes: [],
          lastAiCandleDiffTimes: [],
          lastIndicatorTimes: [],
          lastAiIndicatorTimes: [],
        });

        let stateGlobalPair = state.globalPairs.find(globalPair => globalPair.idGlobalPair === globalPair.idGlobalPair);

        if (typeof globalPair.lastCandleTimes != 'undefined') {
          Object.keys(globalPair.lastCandleTimes).forEach(timeframeIndex => {
            let lastCandleTime = stateGlobalPair.lastCandleTimes.find(lastCandleTime => lastCandleTime.timeframe === timeframeIndex);
            if (typeof lastCandleTime == 'undefined') {
              stateGlobalPair.lastCandleTimes.push({
                'timeframe': timeframeIndex,
                'lastCandleTime': globalPair.lastCandleTimes[timeframeIndex],
              });
            } else {
              lastCandleTime['lastCandleTime'] = globalPair.lastCandleTimes[timeframeIndex];
            }
          });
        }

        if (typeof globalPair.lastAiCandleDiffTimes != 'undefined') {
          Object.keys(globalPair.lastAiCandleDiffTimes).forEach(timeframeIndex => {
            let lastAiCandleDiffTime = stateGlobalPair.lastAiCandleDiffTimes.find(lastAiCandleDiffTime => lastAiCandleDiffTime.timeframe === timeframeIndex);
            if (typeof lastAiCandleDiffTime == 'undefined') {
              stateGlobalPair.lastAiCandleDiffTimes.push({
                'timeframe': timeframeIndex,
                'lastAiCandleDiffTime': globalPair.lastAiCandleDiffTimes[timeframeIndex],
              });
            } else {
              lastAiCandleDiffTime['lastAiCandleDiffTime'] = globalPair.lastAiCandleDiffTimes[timeframeIndex];
            }
          });
        }

        if (typeof globalPair.lastIndicatorTimes != 'undefined') {
          Object.keys(globalPair.lastIndicatorTimes).forEach(indicatorSetIndex => {
            let timeframes = globalPair.lastIndicatorTimes[indicatorSetIndex];
            let lastIndicatorTime = stateGlobalPair.lastIndicatorTimes.find(lastIndicatorTime => lastIndicatorTime.idIndicatorSet === parseInt(indicatorSetIndex));
            if (typeof lastIndicatorTime == 'undefined') {
              stateGlobalPair.lastIndicatorTimes.push({
                'idIndicatorSet': parseInt(indicatorSetIndex),
                'timeframes': [],
              });
              lastIndicatorTime = stateGlobalPair.lastIndicatorTimes.find(lastIndicatorTime => lastIndicatorTime.idIndicatorSet === parseInt(indicatorSetIndex));
            }
            Object.keys(timeframes).forEach(timeframeIndex => {
              let timeframe = lastIndicatorTime.timeframes.find(timeframe => timeframe['timeframe'] === timeframeIndex);
              if (typeof timeframe == 'undefined') {
                lastIndicatorTime.timeframes.push({
                  'timeframe': timeframeIndex,
                  'lastIndicatorTime': timeframes[timeframeIndex],
                });
              } else {
                timeframe.lastIndicatorTime = timeframes[timeframeIndex];
              }
            });
          });
        }

        if (typeof globalPair.lastAiIndicatorTimes != 'undefined') {
          Object.keys(globalPair.lastAiIndicatorTimes).forEach(aiIndicatorSetIndex => {
            let timeframes = globalPair.lastAiIndicatorTimes[aiIndicatorSetIndex];
            let lastAiIndicatorTime = stateGlobalPair.lastAiIndicatorTimes.find(lastAiIndicatorTime => lastAiIndicatorTime.idIndicatorSet === parseInt(aiIndicatorSetIndex));
            if (typeof lastAiIndicatorTime == 'undefined') {
              stateGlobalPair.lastAiIndicatorTimes.push({
                'idIndicatorSet': parseInt(aiIndicatorSetIndex),
                'timeframes': [],
              });
              lastAiIndicatorTime = stateGlobalPair.lastAiIndicatorTimes.find(lastAiIndicatorTime => lastAiIndicatorTime.idIndicatorSet === parseInt(aiIndicatorSetIndex));
            }
            Object.keys(timeframes).forEach(timeframeIndex => {
              let timeframe = lastAiIndicatorTime.timeframes.find(timeframe => timeframe['timeframe'] === timeframeIndex);
              if (typeof timeframe == 'undefined') {
                lastAiIndicatorTime.timeframes.push({
                  'timeframe': timeframeIndex,
                  'lastAiIndicatorTime': timeframes[timeframeIndex],
                });
              } else {
                timeframe.lastAiIndicatorTime = timeframes[timeframeIndex];
              }
            });
          });
        }
      });
    },
    setGlobalPairsAdd(state, payload) {
      state.globalPairs.push({
        idGlobalPair: payload.idGlobalPair,
        idGlobalCurrency: payload.idGlobalCurrency,
        idCurrency: payload.idCurrency,
        showOnMainPage: false,
        locked: false,
        offset: null,
        timeStart: null,

        lastCandleTimes: [],
        lastAiCandleDiffTimes: [],
        lastIndicatorTimes: [],
        lastAiIndicatorTimes: [],
      });
    },
    setGlobalPairsRootUpdate(state, payload) {
      let globalPair = state.globalPairs.find(item => item.idGlobalPair === payload.idGlobalPair);
      if (globalPair) {
        globalPair.showOnMainPage = payload.showOnMainPage;
      }
      if (state.globalPair.idGlobalPair === payload.idGlobalPair) {
        state.globalPair.showOnMainPage = payload.showOnMainPage;
      }
    },
    setGlobalPairsDelete(state, payload) {
      state.globalPairs = state.globalPairs.filter(item => item.idGlobalPair !== payload.idGlobalPair);
    },
    setGlobalPairsLock(state, payload) {
      state.globalPairs.find(item => item.idGlobalPair === payload.idGlobalPair).locked = true;
    },
    setGlobalPairIndicatorSets(state, payload) {
      state.globalPairIndicatorSets = [];
      Object.keys(payload.globalPairIndicatorSets).forEach(globalPairIndicatorSetIndex => {
        let globalPairIndicatorSets = payload.globalPairIndicatorSets[globalPairIndicatorSetIndex];
        Object.keys(globalPairIndicatorSets).forEach(globalPairIndicatorSetIndex => {
          let globalPairIndicatorSet = globalPairIndicatorSets[globalPairIndicatorSetIndex];
          state.globalPairIndicatorSets.push({
            idGlobalPair: globalPairIndicatorSet.idGlobalPair,
            idIndicatorSet: globalPairIndicatorSet.idIndicatorSet,
          });
        });
      });
    },
    setGlobalPairIndicatorSetsAdd(state, payload) {
      state.globalPairIndicatorSets.push({
        idGlobalPair: payload.idGlobalPair,
        idIndicatorSet: payload.idIndicatorSet,
      });
    },
    setGlobalPairIndicatorSetsDelete(state, payload) {
      state.globalPairIndicatorSets = state.globalPairIndicatorSets.filter(item => !(item.idGlobalPair === payload.idGlobalPair && item.idIndicatorSet === payload.idIndicatorSet));
    },
    setGlobalPairExchanges(state, payload) {
      state.globalPairExchanges = [];
      Object.keys(payload.globalPairExchanges).forEach(globalPairIndex => {
        let globalPairExchange = payload.globalPairExchanges[globalPairIndex];
        state.globalPairExchanges.push({
          idGlobalPair: globalPairExchange.idGlobalPair,
          idExchange: globalPairExchange.idExchange,
          usage: globalPairExchange.usage,
          timeStart: globalPairExchange.timeStart,
          lastCandle: globalPairExchange.lastCandle,
        });
      });
    },
    setGlobalPairExchangesAdd(state, payload) {
      state.globalPairExchanges.push({
        idGlobalPair: payload.idGlobalPair,
        idExchange: payload.idExchange,
        usage: payload.usage,
        timeStart: null,
        lastCandle: null,
      });
    },
    setGlobalPairExchangesUpdate(state, payload) {
      let globalPairExchange = state.globalPairExchanges.find(item => ((item.idGlobalPair === payload.idGlobalPair) && (item.idExchange === payload.idExchange)));
      globalPairExchange.usage = payload.usage;
    },
    setGlobalPairExchangesDelete(state, payload) {
      state.globalPairExchanges = state.globalPairExchanges.filter(item => !((item.idGlobalPair === payload.idGlobalPair) && (item.idExchange === payload.idExchange)));
    },
    setCurrencies(state, payload) {
      state.currencies = [];
      Object.keys(payload.currencies).map(currencyIndex => {
        let currency = payload.currencies[currencyIndex];
        state.currencies.push({
          idCurrency: currency.idCurrency,
          code: currency.code,
          locked: currency.locked,
          blockchains: Object.keys(currency.blockchains).map(currencyBlockchainIndex => {
            let currencyBlockchain = currency.blockchains[currencyBlockchainIndex];
            return {
              idBlockchain: currencyBlockchain.idBlockchain,
              contract: currencyBlockchain.contract,
              main: currencyBlockchain.main,
              locked: currencyBlockchain.locked,
            };
          })
        });
      });
    },
    setCurrenciesCreate(state, payload) {
      state.currencies.push({
        idCurrency: payload.idCurrency,
        code: payload.code,
        locked: false,
        blockchains: [],
      });
    },
    setCurrenciesUpdate(state, payload) {
      if (typeof state.currencies.find != 'undefined') {
        let currency = state.currencies.find(item => item.idCurrency === payload.idCurrency);
        currency.code = payload.code;
      }
      if (state.currency.idCurrency === payload.idCurrency) {
        state.currency.code = payload.code;
      }
    },
    setCurrenciesDelete(state, payload) {
      if (typeof state.currencies.find != 'undefined') {
        state.currencies = state.currencies.filter(item => item.idCurrency !== payload.idCurrency);
      }
      if (state.currency.idCurrency === payload.idCurrency) {
        state.currency.idCurrency = null;
        state.currency.code = '';
        state.currency.locked = false;
        state.currency.blockchains = [];
      }
    },
    setCurrenciesLock(state, payload) {
      if (typeof state.currencies.find != 'undefined') {
        state.currencies.find(item => item.idCurrency === payload.idCurrency).locked = true;
      }
      if (state.currency.idCurrency === payload.idCurrency) {
        state.currency.locked = true;
      }
    },
    setCurrencyBlockchains(state, payload) {
      state.currencyBlockchains = [];
      Object.keys(payload.currencyBlockchains).map(currencyBlockchainIndex1 => {
        Object.keys(payload.currencyBlockchains[currencyBlockchainIndex1]).map(currencyBlockchainIndex2 => {
          let currencyBlockchain = payload.currencyBlockchains[currencyBlockchainIndex1][currencyBlockchainIndex2];
          state.currencyBlockchains.push({
            idCurrency: currencyBlockchain.idCurrency,
            idBlockchain: currencyBlockchain.idBlockchain,
            contract: currencyBlockchain.contract,
            main: currencyBlockchain.main,
            locked: currencyBlockchain.locked,
          });
        });
      });
    },
    setCurrencyBlockchainsCreate(state, payload) {
      state.currencyBlockchains.push({
        idCurrency: payload.idCurrency,
        idBlockchain: payload.idBlockchain,
        contract: payload.contract,
        main: payload.main,
        locked: false,
      });
    },
    setCurrencyBlockchainsUpdate(state, payload) {
      console.log('setCurrencyBlockchainsUpdate');
      if (typeof state.currencyBlockchains.find != 'undefined') {
        let currencyBlockchain = state.currencyBlockchains.find(item => item.idCurrency === payload.idCurrency && item.idBlockchain === payload.idBlockchain);
        currencyBlockchain.contract = payload.contract;
        currencyBlockchain.main = payload.main;
        console.log('setCurrencyBlockchainsUpdate set');
      }
    },
    setCurrencyBlockchainsDelete(state, payload) {
      if (typeof state.currencyBlockchains.find != 'undefined') {
        state.currencyBlockchains = state.currencyBlockchains.filter(item => !(item.idCurrency === payload.idCurrency && item.idBlockchain === payload.idBlockchain));
      }
    },
    setCurrencyBlockchainsLock(state, payload) {
      if (typeof state.currencyBlockchains.find != 'undefined') {
        state.currencyBlockchains.find(item => item.idCurrency === payload.idCurrency && item.idBlockchain === payload.idBlockchain).locked = true;
      }
    },
    setGlobalCurrencies(state, payload) {
      state.globalCurrencies = [];
      Object.keys(payload.globalCurrencies).forEach(globalCurrencyIndex => {
        let globalCurrency = payload.globalCurrencies[globalCurrencyIndex];
        let currencyBlockchains = [];
        if (typeof globalCurrency.currencies != 'undefined') {
          Object.keys(globalCurrency.currencies).forEach(globalCurrencyCurrencyIndex => {
            let globalCurrencyCurrency = globalCurrency.currencies[globalCurrencyCurrencyIndex];
            currencyBlockchains.push({
              idGlobalCurrency: globalCurrencyCurrency.idGlobalCurrency,
              idCurrency: globalCurrencyCurrency.idCurrency,
            });
          });
        }

        state.globalCurrencies.push({
          idGlobalCurrency: globalCurrency.idGlobalCurrency,
          idCurrency: globalCurrency.idCurrency,
          currencyBlockchains: currencyBlockchains,
          locked: globalCurrency.locked,
        });
      });
    },
    setGlobalCurrenciesAdd(state, payload) {
      state.globalCurrencies.push({
        idGlobalCurrency: payload.idGlobalCurrency,
        idCurrency: payload.idCurrency,
        locked: false,
        currencyBlockchains: [],
      });
    },
    setGlobalCurrenciesDelete(state, payload) {
      state.globalCurrencies = state.globalCurrencies.filter(item => item.idGlobalCurrency !== payload.idGlobalCurrency);
    },
    setGlobalCurrenciesLock(state, payload) {
      state.globalCurrencies.find(item => item.idGlobalCurrency === payload.idGlobalCurrency).locked = true;
    },
    setGlobalCurrenciesCurrencyAdd(state, payload) {
      let globalCurrency = state.globalCurrencies.find(item => item.idGlobalCurrency === payload.idGlobalCurrency);
      globalCurrency.currencyBlockchains.push({
        idGlobalCurrency: payload.idGlobalCurrency,
        idCurrency: payload.idCurrency,
      });
    },
    setGlobalCurrenciesCurrencyDelete(state, payload) {
      let globalCurrency = state.globalCurrencies.find(item => item.idGlobalCurrency === payload.idGlobalCurrency);
      globalCurrency.currencyBlockchains = globalCurrency.currencyBlockchains.filter(item => item.idCurrency !== payload.idCurrency);
    },
    setExchanges(state, payload) {
      state.exchanges = [];
      Object.keys(payload.exchanges).forEach(exchangeIndex => {
        let exchange = payload.exchanges[exchangeIndex];
        state.exchanges.push({
          idExchange: exchange.idExchange,
          code: exchange.code,
          locked: exchange.locked,
          blockStore: exchange.blockStore,
          blockStream: exchange.blockStream,
        });
      });
    },
    setExchangesCreate(state, payload) {
      state.exchanges.push({
        idExchange: payload.idExchange,
        code: payload.code,
        locked: false,
        blockStore: true,
        blockStream: true,
      });
    },
    setExchangesUpdate(state, payload) {
      state.exchanges.find(item => item.idExchange === payload.idExchange).code = payload.code;
    },
    setExchangesStatus(state, payload) {
      let exchange = state.exchanges.find(item => item.idExchange === payload.idExchange);
      exchange.blockStore = payload.blockStore;
      exchange.blockStream = payload.blockStream;
    },
    setExchangesDelete(state, payload) {
      state.exchanges = state.exchanges.filter(item => item.idExchange !== payload.idExchange);
    },
    setExchangesLock(state, payload) {
      state.exchanges.find(item => item.idExchange === payload.idExchange).locked = true;
    },
    setIndicators(state, payload) {
      state.indicators = [];
      Object.keys(payload.indicators).map(indicatorIndex => {
        let indicator = payload.indicators[indicatorIndex];
        state.indicators.push({
          idIndicator: indicator.idIndicator,
          name: indicator.name,
          math: indicator.math,
          locked: indicator.locked,
        });
      });
    },
    setIndicatorsCreate(state, payload) {
      state.indicators.push({
        idIndicator: payload.idIndicator,
        name: payload.name,
        math: payload.math,
        locked: false,
      });
    },
    setIndicatorsUpdate(state, payload) {
      let indicator = state.indicators.find(item => item.idIndicator === payload.idIndicator);
      indicator.name = payload.name;
      indicator.math = payload.math;
    },
    setIndicatorsDelete(state, payload) {
      state.indicators = state.indicators.filter(item => item.idIndicator !== payload.idIndicator);
    },
    setIndicatorsLock(state, payload) {
      state.indicators.find(item => item.idIndicator === payload.idIndicator).locked = true;
    },
    setIndicatorParams(state, payload) {
      state.indicatorParams = [];
      Object.keys(payload.indicatorParams).forEach(indicatorParamIndex => {
        let indicatorParam = payload.indicatorParams[indicatorParamIndex];
        state.indicatorParams.push({
          idIndicatorParam: indicatorParam.idIndicatorParam,
          idIndicator: indicatorParam.idIndicator,
          name: indicatorParam.name,
          type: indicatorParam.type,
          index: indicatorParam.index,
          defaultValue: indicatorParam.defaultValue,
        });
      });
    },
    setIndicatorParamsCreate(state, payload) {
      state.indicatorParams.push({
        idIndicatorParam: payload.idIndicatorParam,
        idIndicator: payload.idIndicator,
        name: payload.name,
        type: payload.type,
        index: payload.index,
        defaultValue: payload.defaultValue,
      });
    },
    setIndicatorParamsUpdate(state, payload) {
      let indicatorParam = state.indicatorParams.find(item => item.idIndicatorParam === payload.idIndicatorParam);
      indicatorParam.idIndicator = payload.idIndicator;
      indicatorParam.name = payload.name;
      indicatorParam.type = payload.type;
      indicatorParam.defaultValue = payload.defaultValue;
    },
    setIndicatorParamsDelete(state, payload) {
      state.indicatorParams = state.indicatorParams.filter(item => item.idIndicatorParam !== payload.idIndicatorParam);
    },
    setIndicatorOuts(state, payload) {
      state.indicatorOuts = [];
      Object.keys(payload.indicatorOuts).forEach(indicatorOutIndex => {
        let indicatorOut = payload.indicatorOuts[indicatorOutIndex];
        state.indicatorOuts.push({
          idIndicatorOut: indicatorOut.idIndicatorOut,
          idIndicator: indicatorOut.idIndicator,
          name: indicatorOut.name,
          index: indicatorOut.index,
        });
      });
    },
    setIndicatorOutsCreate(state, payload) {
      state.indicatorOuts.push({
        idIndicatorOut: payload.idIndicatorOut,
        idIndicator: payload.idIndicator,
        name: payload.name,
        index: payload.index,
      });
    },
    setIndicatorOutsUpdate(state, payload) {
      let indicatorParam = state.indicatorOuts.find(item => item.idIndicatorOut === payload.idIndicatorOut);
      indicatorParam.idIndicator = payload.idIndicator;
      indicatorParam.name = payload.name;
    },
    setIndicatorOutsDelete(state, payload) {
      state.indicatorOuts = state.indicatorOuts.filter(item => item.idIndicatorOut !== payload.idIndicatorOut);
    },
    setIndicatorSets(state, payload) {
      state.indicatorSets = [];
      Object.keys(payload.indicatorSets).forEach(indicatorSetIndex => {
        let indicatorSet = payload.indicatorSets[indicatorSetIndex];
        state.indicatorSets.push({
          idIndicatorSet: indicatorSet.idIndicatorSet,
          idIndicator: indicatorSet.idIndicator,
          locked: indicatorSet.locked,
          values: indicatorSet.values,
        });
      });
    },
    setIndicatorSetsCreate(state, payload) {
      state.indicatorSets.push({
        idIndicatorSet: payload.idIndicatorSet,
        idIndicator: payload.idIndicator,
        locked: false,
        values: payload.values,
      });
    },
    setIndicatorSetsUpdate(state, payload) {
      let indicatorSet = state.indicatorSets.find(item => item.idIndicatorSet === payload.idIndicatorSet);
      indicatorSet.idIndicator = payload.idIndicator;
      indicatorSet.locked = payload.locked;
      indicatorSet.values = payload.values;
    },
    setIndicatorSetsDelete(state, payload) {
      state.indicatorSets = state.indicatorSets.filter(item => item.idIndicatorSet !== payload.idIndicatorSet);
    },
    setIndicatorSetsLock(state, payload) {
      state.indicatorSets.find(item => item.idIndicatorSet === payload.idIndicatorSet).locked = true;
    },
    setIndicator(state, payload) {
      state.indicator.idIndicator = payload.idIndicator;
      state.indicator.name = payload.name;
      state.indicator.math = payload.math;
      state.indicator.locked = payload.locked;
    },
    setLanguages(state, payload) {
      state.languages = [];
      Object.keys(payload.languages).map(languageIndex => {
        let language = payload.languages[languageIndex];
        state.languages.push({
          idLanguage: language.idLanguage,
          code: language.code,
          selfTitle: language.selfTitle,
          rtl: language.rtl,
          locked: language.locked,
        });
      });
    },
    setLanguageRoot(state, payload) {
      state.language.idLanguage = payload.language.idLanguage;
      state.language.code = payload.language.code;
      state.language.selfTitle = payload.language.selfTitle;
      state.language.rtl = payload.language.rtl;
      state.language.locked = payload.language.locked;
    },
    setLanguagesCreate(state, payload) {
      state.languages.push({
        idLanguage: payload.idLanguage,
        code: payload.code,
        selfTitle: payload.selfTitle,
        rtl: payload.rtl,
        locked: false,
      });
    },
    setLanguagesUpdate(state, payload) {
      let language = state.languages.find(item => item.idLanguage === payload.idLanguage);
      language.code = payload.code;
      language.selfTitle = payload.selfTitle;
      language.rtl = payload.rtl;
    },
    setLanguagesDelete(state, payload) {
      state.languages = state.languages.filter(item => item.idLanguage !== payload.idLanguage);
    },
    setLanguagesLock(state, payload) {
      state.languages.find(item => item.idLanguage === payload.idLanguage).locked = true;
    },
    setLanguageLocales(state, payload) {
      state.locales = [];
      Object.keys(payload.locales).map(languageIndex => {
        let language = payload.locales[languageIndex];
        state.locales.push({
          idLocale: language.idLocale,
          idLanguage: language.idLanguage,
          idCountry: language.idCountry,
          currencySymbol: language.currencySymbol,
          formatDate: language.formatDate,
          formatTime: language.formatTime,
          formatNumber: language.formatNumber,
          formatAddress: language.formatAddress,
          formatTelephone: language.formatTelephone,
          metric: language.metric,
          collation: language.collation,
          locked: language.locked,
        });
      });
    },
    setLanguageLocalesCreate(state, payload) {
      state.locales.push({
        idLocale: payload.idLocale,
        idLanguage: payload.idLanguage,
        idCountry: payload.idCountry,
        currencySymbol: payload.currencySymbol,
        formatDate: payload.formatDate,
        formatTime: payload.formatTime,
        formatNumber: payload.formatNumber,
        formatAddress: payload.formatAddress,
        formatTelephone: payload.formatTelephone,
        metric: payload.metric,
        collation: payload.collation,
        locked: false,
      });
    },
    setLanguageLocalesUpdate(state, payload) {
      let language = state.locales.find(item => item.idLocale === payload.idLocale);
      language.currencySymbol = payload.currencySymbol;
      language.formatDate = payload.formatDate;
      language.formatTime = payload.formatTime;
      language.formatNumber = payload.formatNumber;
      language.formatAddress = payload.formatAddress;
      language.formatTelephone = payload.formatTelephone;
      language.metric = payload.metric;
      language.collation = payload.collation;
    },
    setLanguageLocalesDelete(state, payload) {
      state.locales = state.locales.filter(item => item.idLocale !== payload.idLocale);
    },
    setLanguageLocalesLock(state, payload) {
      state.locales.find(item => item.idLocale === payload.idLocale).locked = true;
    },
    setCountry(state, payload) {
      state.country.idCountry = payload.country.idCountry;
      state.country.code = payload.country.code;
      state.country.locked = payload.country.locked;
    },
    setCountries(state, payload) {
      state.countries = [];
      Object.keys(payload.countries).map(countryIndex => {
        let country = payload.countries[countryIndex];
        state.countries.push({
          idCountry: country.idCountry,
          code: country.code,
          locked: country.locked,
        });
      });
    },
    setCountriesCreate(state, payload) {
      state.countries.push({
        idCountry: payload.idCountry,
        code: payload.code,
        locked: false,
      });
    },
    setCountriesUpdate(state, payload) {
      if (typeof state.countries.find != 'undefined') {
        let currency = state.countries.find(item => item.idCountry === payload.idCountry);
        currency.code = payload.code;
      }
      if (state.country.idCountry === payload.idCountry) {
        state.country.code = payload.code;
      }
    },
    setCountriesDelete(state, payload) {
      if (typeof state.countries.filter != 'undefined') {
        state.countries = state.countries.filter(item => item.idCountry !== payload.idCountry);
      }
      if (state.country.idCountry === payload.idCountry) {
        state.country.idCountry = null;
        state.country.code = '';
        state.country.locked = false;
      }
    },
    setCountriesLock(state, payload) {
      if (typeof state.countries.find != 'undefined') {
        state.countries.find(item => item.idCountry === payload.idCountry).locked = true;
      }
      if (state.country.idCountry === payload.idCountry) {
        state.country.locked = true;
      }
    },
    setCountryLanguages(state, payload) {
      state.countryLanguages = [];
      Object.keys(payload.countryLanguages).map(countryLanguageIndex1 => {
        Object.keys(payload.countryLanguages[countryLanguageIndex1]).map(countryLanguageIndex2 => {
          let countryLanguage = payload.countryLanguages[countryLanguageIndex1][countryLanguageIndex2];
          state.countryLanguages.push({
            idCountry: countryLanguage.idCountry,
            idLanguage: countryLanguage.idLanguage,
            name: countryLanguage.name,
            locked: countryLanguage.locked,
          });
        });
      });
    },
    setCountryLanguagesCreate(state, payload) {
      state.countryLanguages.push({
        idCountry: payload.idCountry,
        idLanguage: payload.idLanguage,
        name: payload.name,
        locked: false,
      });
    },
    setCountryLanguagesUpdate(state, payload) {
      if (typeof state.countryLanguages.find != 'undefined') {
        let currencyLanguage = state.countryLanguages.find(item => item.idCountry === payload.idCountry && item.idLanguage === payload.idLanguage);
        currencyLanguage.name = payload.name;
      }
    },
    setCountryLanguagesDelete(state, payload) {
      if (typeof state.countryLanguages.filter != 'undefined') {
        state.countryLanguages = state.countryLanguages.filter(item => !(item.idCountry === payload.idCountry && item.idLanguage === payload.idLanguage));
      }
    },
    setCountryLanguagesLock(state, payload) {
      if (typeof state.countryLanguages.find != 'undefined') {
        console.log(state.countryLanguages, payload.idCountry, payload.idLanguage);
        let countryLanguage = state.countryLanguages.find(item => item.idCountry === payload.idCountry && item.idLanguage === payload.idLanguage);
        console.log(countryLanguage);
        if (typeof countryLanguage != 'undefined') {
          countryLanguage.locked = true;
        }
      }
    },
    setBlockchains(state, payload) {
      state.blockchains = [];
      Object.keys(payload.blockchains).map(blockchainIndex => {
        let blockchain = payload.blockchains[blockchainIndex];
        state.blockchains.push({
          idBlockchain: blockchain.idBlockchain,
          name: blockchain.name,
          preg: blockchain.preg,
          tag: blockchain.tag,
          locked: blockchain.locked,
        });
      });
    },
    setBlockchainsCreate(state, payload) {
      state.blockchains.push({
        idBlockchain: payload.idBlockchain,
        name: payload.name,
        preg: payload.preg,
        tag: payload.tag,
        locked: false,
      });
    },
    setBlockchainsUpdate(state, payload) {
      let blockchain = state.blockchains.find(item => item.idBlockchain === payload.idBlockchain);
      blockchain.name = payload.name;
      blockchain.preg = payload.preg;
      blockchain.tag = payload.tag;
    },
    setBlockchainsDelete(state, payload) {
      state.blockchains = state.blockchains.filter(item => item.idBlockchain !== payload.idBlockchain);
    },
    setBlockchainsLock(state, payload) {
      state.blockchains.find(item => item.idBlockchain === payload.idBlockchain).locked = true;
    },
    setSettings(state, payload) {
      state.settings = [];
      Object.keys(payload.settings).forEach(settingIndex => {
        let setting = payload.settings[settingIndex];
        state.settings.push({
          idSetting: setting.idSetting,
          setting: setting.setting,
          name: setting.name,
          type: setting.type,
          value: setting.value,
        });
      });
    },
    setSettingsCreate(state, payload) {
      state.settings.push({
        idSetting: payload.idSetting,
        setting: payload.setting,
        name: payload.name,
        type: payload.type,
        value: payload.value,
      });
    },
    setSettingsUpdate(state, payload) {
      let setting = state.settings.find(item => item.idSetting === payload.idSetting);
      if (setting) {
        setting.value = payload.value;
      }
    },
    setTradeGroups(state, payload) {
      state.tradeGroups = [];
      Object.keys(payload.tradeGroups).forEach(tradeGroupIndex => {
        let tradeGroup = payload.tradeGroups[tradeGroupIndex];
        state.tradeGroups.push({
          idTradeGroup: tradeGroup.idTradeGroup,
          name: tradeGroup.name,
          telegram: tradeGroup.telegram,
          timeframes: tradeGroup.timeframes,
          locked: tradeGroup.locked,
        });
      });
    },
    setTradeGroupsCreate(state, payload) {
      state.tradeGroups.push({
        idTradeGroup: payload.idTradeGroup,
        name: payload.name,
        telegram: payload.telegram,
        timeframes: payload.timeframes,
        locked: false,
      });
    },
    setTradeGroupsUpdate(state, payload) {
      let tradeGroup = state.tradeGroups.find(item => item.idTradeGroup === payload.idTradeGroup);
      tradeGroup.name = payload.name;
      tradeGroup.telegram = payload.telegram;
      tradeGroup.timeframes = payload.timeframes;
    },
    setTradeGroupsDelete(state, payload) {
      state.tradeGroups = state.tradeGroups.filter(item => item.idTradeGroup !== payload.idTradeGroup);
    },
    setTradeGroupsLock(state, payload) {
      state.tradeGroups.find(item => item.idTradeGroup === payload.idTradeGroup).locked = true;
    },
    setExchangePairs(state, payload) {
      state.exchangePairs = [];
      Object.keys(payload.exchangePairs).forEach(exchangePairIndex => {
        let exchangePair = payload.exchangePairs[exchangePairIndex];
        state.exchangePairs.push({
          idExchangePair: exchangePair.idExchangePair,
          idExchange: exchangePair.idExchange,
          idCurrencyPrimary: exchangePair.idCurrencyPrimary,
          idCurrencySecondary: exchangePair.idCurrencySecondary,
          offset: exchangePair.offset,
          locked: exchangePair.locked,
        });
      });
    },
    setExchangePairsCreate(state, payload) {
      state.exchangePairs.push({
        idExchangePair: payload.idExchangePair,
        idExchange: payload.idExchange,
        idCurrencyPrimary: payload.idCurrencyPrimary,
        idCurrencySecondary: payload.idCurrencySecondary,
        offset: payload.offset,
        locked: false,
      });
    },
    setExchangePairsDelete(state, payload) {
      state.exchangePairs = state.exchangePairs.filter(item => item.idExchangePair !== payload.idExchangePair);
    },
    setExchangePairsLock(state, payload) {
      state.exchangePairs.find(item => item.idExchangePair === payload.idExchangePair).locked = true;
    },
    setExchangeCurrencies(state, payload) {
      state.exchangeCurrencies = [];
      Object.keys(payload.exchangeCurrencies).forEach(exchangeCurrencyIndex => {
        let exchangeCurrency = payload.exchangeCurrencies[exchangeCurrencyIndex];
        state.exchangeCurrencies.push({
          idExchangeCurrency: exchangeCurrency.idExchangeCurrency,
          idExchange: exchangeCurrency.idExchange,
          idCurrency: exchangeCurrency.idCurrency,
          locked: exchangeCurrency.locked,
        });
      });
    },
    setExchangeCurrenciesAdd(state, payload) {
      state.exchangeCurrencies.push({
        idExchangeCurrency: payload.idExchangeCurrency,
        idExchange: payload.idExchange,
        idCurrency: payload.idCurrency,
        locked: false,
      });
    },
    setExchangeCurrenciesDelete(state, payload) {
      state.exchangeCurrencies = state.exchangeCurrencies.filter(item => item.idExchangeCurrency !== payload.idExchangeCurrency);
    },
    setExchangeCurrenciesLock(state, payload) {
      state.exchangeCurrencies.find(item => item.idExchangeCurrency === payload.idExchangeCurrency).locked = true;
    },
    setPriceCurrencies(state, payload) {
      state.priceCurrencies = [];
      Object.keys(payload.priceCurrencies).map(currencyIndex => {
        let priceCurrency = payload.priceCurrencies[currencyIndex];
        state.priceCurrencies.push({
          indexCurrency: priceCurrency.indexCurrency,
          code: priceCurrency.code,
        });
      });
    },
    setPriceExchange(state, payload) {
      state.priceExchange.indexExchange = payload.indexExchange;
      state.priceExchange.code = payload.code;
    },
    setPriceExchanges(state, payload) {
      state.priceExchanges = [];
      Object.keys(payload.priceExchanges).map(exchangeIndex => {
        let priceExchange = payload.priceExchanges[exchangeIndex];
        state.priceExchanges.push({
          indexExchange: priceExchange.indexExchange,
          code: priceExchange.code,
        });
      });
    },
    setPricePairs(state, payload) {
      state.pricePairs = [];
      Object.keys(payload.pricePairs).map(pairIndex => {
        let pricePair = payload.pricePairs[pairIndex];
        state.pricePairs.push({
          indexPair: pricePair.indexPair,
          indexExchange: pricePair.indexExchange,
          indexCurrencyPrimary: pricePair.indexCurrencyPrimary,
          indexCurrencySecondary: pricePair.indexCurrencySecondary,
          offset: pricePair.offset,
          timeStart: pricePair.timeStart,
          timeStore: pricePair.timeStore,
          valid: pricePair.valid,
        });
      });
    },
    setPricePairsUpdateTimeStart(state, payload) {
      let pricePair = state.pricePairs.find(item => item.indexPair === payload.indexPair);
      if (typeof pricePair == 'undefined') {
        return;
      }
      pricePair.timeStart = payload.timeStart;
    },
    setPricePairsUpdateTimeStore(state, payload) {
      let pricePair = state.pricePairs.find(item => item.indexPair === payload.indexPair);
      if (typeof pricePair == 'undefined') {
        return;
      }
      pricePair.timeStore = payload.timeStore;
    },
    setPricePairsUpdateValid(state, payload) {
      let pricePair = state.pricePairs.find(item => item.indexPair === payload.indexPair);
      if (typeof pricePair == 'undefined') {
        return;
      }
      pricePair.valid = payload.valid;
    },
    setUserWallets(state, payload) {
      state.userWallets = [];
      Object.keys(payload.userWallets).map(userWalletIndex => {
        let userWallet = payload.userWallets[userWalletIndex];
        state.userWallets.push({
          idUserWallet: userWallet.idUserWallet,
          idUser: userWallet.idUser,
          idBlockchain: userWallet.idBlockchain,
          address: userWallet.address,
          tag: userWallet.tag,
        });
      });

    },
    setUserWalletsCreate(state, payload) {
      state.userWallets.push({
        idUserWallet: payload.idUserWallet,
        idUser: payload.idUser,
        idBlockchain: payload.idBlockchain,
        address: payload.address,
        tag: payload.tag,
      });
    },
    setUserWalletsUpdate(state, payload) {
      let userWallet = state.userWallets.find(item => item.idUserWallet === payload.idUserWallet);
      userWallet.address = payload.address;
      userWallet.tag = payload.tag;
    },
    setUserWalletsDelete(state, payload) {
      state.userWallets = state.userWallets.filter(item => item.idUserWallet !== payload.idUserWallet);
    },
    setBackups(state, payload) {
      state.backups = [];
      Object.keys(payload.backups).map(backupIndex => {
        let backup = payload.backups[backupIndex];
        state.backups.push({
          dir: backup.dir,
          time: backup.time,
          name: backup.name,
          type: backup.type,
          databaseMain: backup.databaseMain,
          databasePrice: backup.databasePrice,
          finished: backup.finished,
        });
      });
    },
    setBackupsCreate(state, payload) {
      state.backups.push({
        dir: payload.dir,
        time: payload.time,
        name: payload.name,
        type: payload.type,
        databaseMain: payload.databaseMain,
        databasePrice: payload.databasePrice,
        finished: payload.finished,
      });
    },
    setBackupsCreateSuccess(state, payload) {
      state.backups.find(item => item.time === payload.time).finished = true;
    },
    setBackupsCreateError(state, payload) {
      state.backups.find(item => item.time === payload.time).finished = null;
    },
    setBackupsDelete(state, payload) {
      state.backups = state.backups.filter(item => item.time !== payload.time);
    },
    setBackupsRestore(state, payload) {
      state.backups.find(item => item.time === payload.time).finished = false;
    },
    setBackupsRestoreSuccess(state, payload) {
      state.backups.find(item => item.time === payload.time).finished = true;
    },
    setBackupsRestoreError(state, payload) {
      state.backups.find(item => item.time === payload.time).finished = null;
    },
    setMath(state, payload) {
      state.mathSettings.math = payload.math;
      state.mathSettings.types = payload.types;
    },
    registerConfirm(state) {
      state.user.confirmEmail = true;
    },
    restore(state, payload) {
      state.user.restorePassword = {
        emailProgressMessage: null,
        emailProgressType: null,
        confirm: false,
        success: null,
      };
      state.restorePasswordEmail = payload;
    },
    restoreConfirm(state) {
      state.restorePasswordEmail = '';
      state.user.restorePassword.confirm = true;
    },
    restoreSuccess(state) {
      state.user.restorePassword.success = Math.floor(Date.now() / 1000);
    },
    newPasswordRequest(state) {
      state.user.newPassword = {
        emailProgressMessage: null,
        emailProgressType: null,
        code: true,
        success: null,
      };
      state.user.newPasswordExists = true;
    },
    newPasswordEmailProgress(state, payload) {
      state.user.newPassword.emailProgressMessage = payload.message;
      state.user.newPassword.emailProgressType = payload.type;
    },
    newPasswordCancel(state) {
      state.user.newPassword = {
        emailProgressMessage: null,
        emailProgressType: null,
        code: null,
        success: null,
      };
      state.user.newPasswordExists = false;
    },
    newPasswordConfirm(state, payload) {
      state.user.newPassword.code = payload;
      state.user.newPassword.success = Math.floor(Date.now() / 1000);
    },
    changeEmailRequest(state, payload) {
      state.user.changeEmail = {
        emailProgressMessage: null,
        emailProgressType: null,
        newEmail: payload,
        success: null,
      };
    },
    changeEmailEmailProgress(state, payload) {
      state.user.changeEmail.emailProgressMessage = payload.message;
      state.user.changeEmail.emailProgressType = payload.type;
    },
    changeEmailCancel(state) {
      state.user.changeEmail = {
        emailProgressMessage: null,
        emailProgressType: null,
        newEmail: null,
        success: null,
      };
    },
    changeEmailConfirm(state) {
      state.user.changeEmail.success = Math.floor(Date.now() / 1000);
    },
    adminUserUpdate(state, payload) {
      state.user.name = payload.name;
      state.user.admin = payload.admin;
      state.user.block = payload.block;
    },
    setWsConnected(state, payload) {
      state.wsConnected = payload;
    },
    initSession(state) {
      function makeSessionId(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      }

      state.wsSession = localStorage.getItem('wsSession');
      if (state.wsSession === null || state.wsSession === '') {
        state.wsSession = makeSessionId(32);
        localStorage.setItem('wsSession', state.wsSession);
      }
    },
  },
  actions: {
    wsSetUser(context, payload) {
      context.commit('setUser', {
        idUser: payload.idUser,
        email: payload.email,
        name: payload.name,
        theme: payload.theme,
        admin: payload.admin,
        confirmEmail: payload.confirmEmail,
        register: payload.register ?? {
          emailProgressMessage: null,
          emailProgressType: null,
          success: null,
        },
        newPassword: payload.newPassword ?? {
          emailProgressMessage: null,
          emailProgressType: null,
          code: null,
          success: null,
        },
        restorePassword: payload.restorePassword ?? {
          emailProgressMessage: null,
          emailProgressType: null,
          confirm: false,
          success: null,
        },
        changeEmail: payload.changeEmail ?? {
          emailProgressMessage: null,
          emailProgressType: null,
          newEmail: null,
          success: null,
        },
      });
      if (context.state.user.theme !== context.state.theme) {
        context.commit('setTheme', context.state.user.theme);
      }
    },
    wsUnsetUser(context) {
      context.commit('unsetUser');
    },
    setWelcomeScreen(context, payload) {
      context.commit('setWelcomeScreen', payload);
    },
    profileSetName(context, payload) {
      context.commit('profileSetName', payload);
    },
    profileSetTheme(context, payload) {
      context.commit('profileSetTheme', payload);
      context.commit('setTheme', payload);
    },
    profileNewPasswordRequest(context) {
      context.commit('newPasswordRequest');
    },
    profileNewPasswordEmailProgress(context, payload) {
      context.commit('newPasswordEmailProgress', payload);
    },
    profileNewPasswordCancel(context) {
      context.commit('newPasswordCancel');
    },
    profileNewPasswordConfirm(context, payload) {
      context.commit('newPasswordConfirm', payload);
    },
    profileChangeEmailRequest(context, payload) {
      context.commit('changeEmailRequest', payload);
    },
    profileChangeEmailEmailProgress(context, payload) {
      context.commit('changeEmailEmailProgress', payload);
    },
    profileChangeEmailCancel(context) {
      context.commit('changeEmailCancel');
    },
    profileChangeEmailConfirm(context) {
      context.commit('changeEmailConfirm');
    },
    adminUserUpdate(context, payload) {
      context.commit('adminUserUpdate', {
        name: payload.name,
        admin: payload.admin,
        block: payload.block,
      });
    },
    setErrorReason(context, payload) {
      context.commit('setErrorReason', payload);
    },
    unsetErrorReason(context) {
      context.commit('unsetErrorReason');
    },
    setErrorStatus(context, payload) {
      context.commit('setErrorStatus', payload);
    },
    unselectAll(context) {
      context.commit('unselectAll');
    },
    selectLanguage(context, payload) {
      context.commit('selectLanguage', payload);
    },
    selectLocale(context, payload) {
      context.commit('selectLocale', payload);
    },
    selectBlockchain(context, payload) {
      context.commit('selectBlockchain', payload);
    },
    selectCurrency(context, payload) {
      context.commit('selectCurrency', payload);
    },
    selectCurrencySecondary(context, payload) {
      context.commit('selectCurrencySecondary', payload);
    },
    selectCountry(context, payload) {
      context.commit('selectCountry', payload);
    },
    selectExchange(context, payload) {
      context.commit('selectExchange', payload);
    },
    selectGlobalPair(context, payload) {
      context.commit('selectGlobalPair', payload);
    },
    selectGlobalCurrency(context, payload) {
      context.commit('selectGlobalCurrency', payload);
    },
    selectIndicator(context, payload) {
      context.commit('selectIndicator', payload);
    },
    selectIndicatorParam(context, payload) {
      context.commit('selectIndicatorParam', payload);
    },
    selectIndicatorOut(context, payload) {
      context.commit('selectIndicatorOut', payload);
    },
    selectIndicatorSet(context, payload) {
      context.commit('selectIndicatorSet', payload);
    },
    selectSetting(context, payload) {
      context.commit('selectSetting', payload);
    },
    selectTradeGroup(context, payload) {
      context.commit('selectTradeGroup', payload);
    },
    selectUserWallet(context, payload) {
      context.commit('selectUserWallet', payload);
    },
    selectBackup(context, payload) {
      context.commit('selectBackup', payload);
    },
    selectTimeFrame(context, payload) {
      context.commit('selectTimeFrame', payload);
    },
    selectCandleCount(context, payload) {
      context.commit('selectCandleCount', payload);
    },
    selectPriceCurrency(context, payload) {
      context.commit('selectPriceCurrency', payload);
    },
    selectPriceExchange(context, payload) {
      context.commit('selectPriceExchange', payload);
    },
    selectPricePair(context, payload) {
      context.commit('selectPricePair', payload);
    },
    setAuthMode(context, payload) {
      context.commit('setAuthMode', payload);
    },
    setTheme(context, payload) {
      context.commit('setTheme', payload);
    },
    setThemeDoNotShow(context, payload) {
      context.commit('setThemeDoNotShow', payload);
    },
    wsSetLoaded(context, payload) {
      if (context.state.wsLoaded === false && payload === true) {
        console.log('Set loaded true');
      }
      context.commit('setLoaded', payload);
    },
    wsExchangeRoot(context, payload) {
      context.commit('setExchange', payload);
    },
    wsExchangePairRoot(context, payload) {
      context.commit('setExchangePair', payload);
    },
    wsGlobalPair(context, payload) {
      context.commit('setGlobalPair', payload);
    },
    wsGlobalPairDataUpdate(context, payload) {
      context.commit('setGlobalPairDataUpdate', payload);
    },
    wsGlobalPairCalcUpdate(context, payload) {
      context.commit('setGlobalPairCalcUpdate', payload);
    },
    wsGlobalPairs(context, payload) {
      context.commit('setGlobalPairs', payload);
    },
    wsGlobalPairsAdd(context, payload) {
      context.commit('setGlobalPairsAdd', payload);
    },
    wsGlobalPairsRootUpdate(context, payload) {
      context.commit('setGlobalPairsRootUpdate', payload);
    },
    wsGlobalPairsDelete(context, payload) {
      context.commit('setGlobalPairsDelete', payload);
    },
    wsGlobalPairsLock(context, payload) {
      context.commit('setGlobalPairsLock', payload);
    },
    wsGlobalPairIndicatorSets(context, payload) {
      context.commit('setGlobalPairIndicatorSets', payload);
    },
    wsGlobalPairIndicatorSetsAdd(context, payload) {
      context.commit('setGlobalPairIndicatorSetsAdd', payload);
    },
    wsGlobalPairIndicatorSetsDelete(context, payload) {
      context.commit('setGlobalPairIndicatorSetsDelete', payload);
    },
    wsGlobalPairExchanges(context, payload) {
      context.commit('setGlobalPairExchanges', payload);
    },
    wsGlobalPairExchangesAdd(context, payload) {
      context.commit('setGlobalPairExchangesAdd', payload);
    },
    wsGlobalPairExchangesUpdate(context, payload) {
      context.commit('setGlobalPairExchangesUpdate', payload);
    },
    wsGlobalPairExchangesDelete(context, payload) {
      context.commit('setGlobalPairExchangesDelete', payload);
    },
    wsCurrency(context, payload) {
      context.commit('setCurrency', payload);
    },
    wsCurrencies(context, payload) {
      context.commit('setCurrencies', payload);
    },
    wsCurrenciesCreate(context, payload) {
      context.commit('setCurrenciesCreate', payload);
    },
    wsCurrenciesUpdate(context, payload) {
      context.commit('setCurrenciesUpdate', payload);
    },
    wsCurrenciesDelete(context, payload) {
      context.commit('setCurrenciesDelete', payload);
    },
    wsCurrenciesLock(context, payload) {
      context.commit('setCurrenciesLock', payload);
    },
    wsCurrencyBlockchains(context, payload) {
      context.commit('setCurrencyBlockchains', payload);
    },
    wsCurrencyBlockchainsCreate(context, payload) {
      context.commit('setCurrencyBlockchainsCreate', payload);
    },
    wsCurrencyBlockchainsUpdate(context, payload) {
      context.commit('setCurrencyBlockchainsUpdate', payload);
    },
    wsCurrencyBlockchainsDelete(context, payload) {
      context.commit('setCurrencyBlockchainsDelete', payload);
    },
    wsCurrencyBlockchainsLock(context, payload) {
      context.commit('setCurrencyBlockchainsLock', payload);
    },
    wsGlobalCurrencies(context, payload) {
      context.commit('setGlobalCurrencies', payload);
    },
    wsGlobalCurrenciesAdd(context, payload) {
      context.commit('setGlobalCurrenciesAdd', payload);
    },
    wsGlobalCurrenciesDelete(context, payload) {
      context.commit('setGlobalCurrenciesDelete', payload);
    },
    wsGlobalCurrenciesLock(context, payload) {
      context.commit('setGlobalCurrenciesLock', payload);
    },
    wsGlobalCurrenciesCurrencyAdd(context, payload) {
      context.commit('setGlobalCurrenciesCurrencyAdd', payload);
    },
    wsGlobalCurrenciesCurrencyDelete(context, payload) {
      context.commit('setGlobalCurrenciesCurrencyDelete', payload);
    },
    wsGlobalCurrenciesCurrencyLock(context, payload) {
      context.commit('setGlobalCurrenciesCurrencyLock', payload);
    },
    wsExchanges(context, payload) {
      context.commit('setExchanges', payload);
    },
    wsExchangesCreate(context, payload) {
      context.commit('setExchangesCreate', payload);
    },
    wsExchangesUpdate(context, payload) {
      context.commit('setExchangesUpdate', payload);
    },
    wsExchangesStatus(context, payload) {
      context.commit('setExchangesStatus', payload);
    },
    wsExchangesDelete(context, payload) {
      context.commit('setExchangesDelete', payload);
    },
    wsExchangesLock(context, payload) {
      context.commit('setExchangesLock', payload);
    },
    wsIndicators(context, payload) {
      context.commit('setIndicators', payload);
    },
    wsIndicatorsCreate(context, payload) {
      context.commit('setIndicatorsCreate', payload);
    },
    wsIndicatorsUpdate(context, payload) {
      context.commit('setIndicatorsUpdate', payload);
    },
    wsIndicatorsDelete(context, payload) {
      context.commit('setIndicatorsDelete', payload);
    },
    wsIndicatorsLock(context, payload) {
      context.commit('setIndicatorsLock', payload);
    },
    wsIndicatorParams(context, payload) {
      context.commit('setIndicatorParams', payload);
    },
    wsIndicatorParamsCreate(context, payload) {
      context.commit('setIndicatorParamsCreate', payload);
    },
    wsIndicatorParamsUpdate(context, payload) {
      context.commit('setIndicatorParamsUpdate', payload);
    },
    wsIndicatorParamsDelete(context, payload) {
      context.commit('setIndicatorParamsDelete', payload);
    },
    wsIndicatorOuts(context, payload) {
      context.commit('setIndicatorOuts', payload);
    },
    wsIndicatorOutsCreate(context, payload) {
      context.commit('setIndicatorOutsCreate', payload);
    },
    wsIndicatorOutsUpdate(context, payload) {
      context.commit('setIndicatorOutsUpdate', payload);
    },
    wsIndicatorOutsDelete(context, payload) {
      context.commit('setIndicatorOutsDelete', payload);
    },
    wsIndicatorSets(context, payload) {
      context.commit('setIndicatorSets', payload);
    },
    wsIndicatorSetsCreate(context, payload) {
      context.commit('setIndicatorSetsCreate', payload);
    },
    wsIndicatorSetsUpdate(context, payload) {
      context.commit('setIndicatorSetsUpdate', payload);
    },
    wsIndicatorSetsDelete(context, payload) {
      context.commit('setIndicatorSetsDelete', payload);
    },
    wsIndicatorSetsLock(context, payload) {
      context.commit('setIndicatorSetsLock', payload);
    },
    wsIndicatorRoot(context, payload) {
      context.commit('setIndicator', payload);
    },
    wsLanguages(context, payload) {
      context.commit('setLanguages', payload);
    },
    wsLanguageRoot(context, payload) {
      context.commit('setLanguageRoot', payload);
    },
    wsLanguagesCreate(context, payload) {
      context.commit('setLanguagesCreate', payload);
    },
    wsLanguagesUpdate(context, payload) {
      context.commit('setLanguagesUpdate', payload);
    },
    wsLanguagesDelete(context, payload) {
      context.commit('setLanguagesDelete', payload);
    },
    wsLanguagesLock(context, payload) {
      context.commit('setLanguagesLock', payload);
    },
    wsLanguageLocales(context, payload) {
      context.commit('setLanguageLocales', payload);
    },
    wsLanguageLocalesCreate(context, payload) {
      context.commit('setLanguageLocalesCreate', payload);
    },
    wsLanguageLocalesUpdate(context, payload) {
      context.commit('setLanguageLocalesUpdate', payload);
    },
    wsLanguageLocalesDelete(context, payload) {
      context.commit('setLanguageLocalesDelete', payload);
    },
    wsLanguageLocalesLock(context, payload) {
      context.commit('setLanguageLocalesLock', payload);
    },
    wsCountry(context, payload) {
      context.commit('setCountry', payload);
    },
    wsCountries(context, payload) {
      context.commit('setCountries', payload);
    },
    wsCountriesCreate(context, payload) {
      context.commit('setCountriesCreate', payload);
    },
    wsCountriesUpdate(context, payload) {
      context.commit('setCountriesUpdate', payload);
    },
    wsCountriesDelete(context, payload) {
      context.commit('setCountriesDelete', payload);
    },
    wsCountriesLock(context, payload) {
      context.commit('setCountriesLock', payload);
    },
    wsCountryLanguages(context, payload) {
      context.commit('setCountryLanguages', payload);
    },
    wsCountryLanguagesCreate(context, payload) {
      context.commit('setCountryLanguagesCreate', payload);
    },
    wsCountryLanguagesUpdate(context, payload) {
      context.commit('setCountryLanguagesUpdate', payload);
    },
    wsCountryLanguagesDelete(context, payload) {
      context.commit('setCountryLanguagesDelete', payload);
    },
    wsCountryLanguagesLock(context, payload) {
      context.commit('setCountryLanguagesLock', payload);
    },
    wsBlockchains(context, payload) {
      context.commit('setBlockchains', payload);
    },
    wsBlockchainsCreate(context, payload) {
      context.commit('setBlockchainsCreate', payload);
    },
    wsBlockchainsUpdate(context, payload) {
      context.commit('setBlockchainsUpdate', payload);
    },
    wsBlockchainsDelete(context, payload) {
      context.commit('setBlockchainsDelete', payload);
    },
    wsBlockchainsLock(context, payload) {
      context.commit('setBlockchainsLock', payload);
    },
    wsSettings(context, payload) {
      context.commit('setSettings', payload);
    },
    wsSettingsCreate(context, payload) {
      context.commit('setSettingsCreate', payload);
    },
    wsSettingsUpdate(context, payload) {
      context.commit('setSettingsUpdate', payload);
    },
    wsTradeGroups(context, payload) {
      context.commit('setTradeGroups', payload);
    },
    wsTradeGroupsCreate(context, payload) {
      context.commit('setTradeGroupsCreate', payload);
    },
    wsTradeGroupsUpdate(context, payload) {
      context.commit('setTradeGroupsUpdate', payload);
    },
    wsTradeGroupsDelete(context, payload) {
      context.commit('setTradeGroupsDelete', payload);
    },
    wsTradeGroupsLock(context, payload) {
      context.commit('setTradeGroupsLock', payload);
    },
    wsExchangePairs(context, payload) {
      context.commit('setExchangePairs', payload);
    },
    wsExchangePairsCreate(context, payload) {
      context.commit('setExchangePairsCreate', payload);
    },
    wsExchangePairsDelete(context, payload) {
      context.commit('setExchangePairsDelete', payload);
    },
    wsExchangePairsLock(context, payload) {
      context.commit('setExchangePairsLock', payload);
    },
    wsExchangeCurrencies(context, payload) {
      context.commit('setExchangeCurrencies', payload);
    },
    wsExchangeCurrenciesAdd(context, payload) {
      context.commit('setExchangeCurrenciesAdd', payload);
    },
    wsExchangeCurrenciesDelete(context, payload) {
      context.commit('setExchangeCurrenciesDelete', payload);
    },
    wsExchangeCurrenciesLock(context, payload) {
      context.commit('setExchangeCurrenciesLock', payload);
    },
    wsPriceCurrencies(context, payload) {
      context.commit('setPriceCurrencies', payload);
    },
    wsPriceExchange(context, payload) {
      context.commit('setPriceExchange', payload);
    },
    wsPriceExchanges(context, payload) {
      context.commit('setPriceExchanges', payload);
    },
    wsPricePairs(context, payload) {
      context.commit('setPricePairs', payload);
    },
    wsPricePairsUpdateTimeStart(context, payload) {
      context.commit('setPricePairsUpdateTimeStart', payload);
    },
    wsPricePairsUpdateTimeStore(context, payload) {
      context.commit('setPricePairsUpdateTimeStore', payload);
    },
    wsPricePairsUpdateValid(context, payload) {
      context.commit('setPricePairsUpdateValid', payload);
    },
    wsUserWallets(context, payload) {
      context.commit('setUserWallets', payload);
    },
    wsUserWalletsCreate(context, payload) {
      context.commit('setUserWalletsCreate', payload);
    },
    wsUserWalletsUpdate(context, payload) {
      context.commit('setUserWalletsUpdate', payload);
    },
    wsUserWalletsDelete(context, payload) {
      context.commit('setUserWalletsDelete', payload);
    },
    wsBackups(context, payload) {
      context.commit('setBackups', payload);
    },
    wsBackupsCreate(context, payload) {
      context.commit('setBackupsCreate', payload);
    },
    wsBackupsCreateSuccess(context, payload) {
      context.commit('setBackupsCreateSuccess', payload);
    },
    wsBackupsCreateError(context, payload) {
      context.commit('setBackupsCreateError', payload);
    },
    wsBackupsDelete(context, payload) {
      context.commit('setBackupsDelete', payload);
    },
    wsBackupsRestore(context, payload) {
      context.commit('setBackupsRestore', payload);
    },
    wsBackupsRestoreSuccess(context, payload) {
      context.commit('setBackupsRestoreSuccess', payload);
    },
    wsBackupsRestoreError(context, payload) {
      context.commit('setBackupsRestoreError', payload);
    },
    wsMath(context, payload) {
      context.commit('setMath', payload);
    },
    wsRegisterConfirm(context) {
      context.commit('registerConfirm');
    },
    wsRestore(context, payload) {
      context.commit('restore', payload);
    },
    wsRestoreConfirm(context) {
      context.commit('restoreConfirm');
    },
    wsRestoreSuccess(context) {
      context.commit('restoreSuccess');
    },
    wsMessageHandler(context, payload) {
      let signal = payload.signal;
      let data = payload.json;
      if (signal !== 'pong') {
        console.log('wsMessageHandler', signal, data);
      }
    }
  },
})
