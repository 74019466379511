<template>
  <div class="container">
    <header class="d-flex flex-wrap justify-content-between align-items-center py-3 mb-4 border-bottom sticky-top">
      <router-link to="/" class="navbar-brand">
        <span class="fs-4">Хлебушек</span>
      </router-link>

      <ul class="nav nav-pills mt-1 mt-md-0">
        <li class="nav-item">
          <router-link to="/admin" class="nav-link" v-if="$store.state.user.admin">Админка</router-link>
        </li>
        <li class="nav-item dropdown" id="dropdownThemeSwitcher" v-if="themeDoNotShow === false">
          <span class="btn btn-info nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i :class="currentIcon"></i>
          </span>
          <ul class="dropdown-menu" aria-labelledby="themeSwitcher">
            <li><span class="dropdown-item" @click="setTopMenuTheme('light')"><i class="bi bi-brightness-high"></i> Светлая</span></li>
            <li><span class="dropdown-item" @click="setTopMenuTheme('dark')"><i class="bi bi-moon"></i> Тёмная</span></li>
            <li><span class="dropdown-item" @click="setTopMenuTheme('auto')"><i class="bi bi-circle-half"></i> Авто</span></li>
            <li><hr class="dropdown-divider"></li>
            <li><span class="dropdown-item" @click="hideTopMenuTheme()"><i class="bi bi-x-lg"></i> Убрать из меню</span></li>
          </ul>
        </li>
        <li class="nav-item">
          <router-link to="/profile" class="nav-link" v-if="!$store.state.user.name">Вход</router-link>
        </li>
        <li class="nav-item dropdown" v-if="$store.state.user.name" id="dropdownProfile">
          <button class="btn btn-info nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ $store.state.user.name }}
          </button>
          <ul class="dropdown-menu">
            <li>
              <router-link to="/helper" class="dropdown-item">Помощник</router-link>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <router-link to="/profile" class="dropdown-item">Профиль</router-link>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" href="javascript:void(0)" @click="actionLogout">Выход</a>
            </li>
          </ul>
        </li>
      </ul>
    </header>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "TopMenu",
  data() {
    return {
      themes: {
        none: 'bi bi-slash-circle',
        light: 'bi bi-brightness-high',
        dark: 'bi bi-moon',
        auto: 'bi bi-circle-half'
      },
      themeInterval: null,
    };
  },
  props: {
    isAdminPanel: Boolean,
  },
  computed: {
    ...mapState(['wsLoaded', 'theme', 'themeDoNotShow', 'user']),
    currentIcon() {
      return this.themes[this.theme ?? 'none'];
    },
  },
  watch: {
    theme() {
      this.applyTheme();
    }
  },
  emits: ['actionLogout'],
  methods: {
    ...mapActions(['setTheme', 'setThemeDoNotShow']),
    actionLogout() {
      this.$emit('actionLogout');
    },
    applyTheme() {
      if (this.theme === null) {
        document.documentElement.removeAttribute('data-bs-theme');
      } else if (this.theme === 'auto') {
        const autoMode = window.matchMedia('(prefers-color-scheme: light)').matches;

        document.documentElement.setAttribute('data-bs-theme', autoMode ? 'light' : 'dark');
      } else {
        document.documentElement.setAttribute('data-bs-theme', this.theme);
      }
    },
    updateThemeIfAuto() {
      if (this.theme === 'auto') {
        this.applyTheme();
      }
    },
    setTopMenuTheme(theme) {
      this.setTheme(theme);
      if (this.user.theme !== this.theme && this.user.idUser !== null) {
        this.$wsSend('profileUpdateTheme', {
          newTheme: theme,
        });
      }
    },
    hideTopMenuTheme() {
      this.setThemeDoNotShow(true);
    },
  },
  mounted() {
    this.applyTheme();
    this.themeInterval = setInterval(this.updateThemeIfAuto, 60000);
  },
  beforeUnmount() {
    if (this.themeInterval) {
      clearInterval(this.themeInterval);
    }
  },
}
</script>

<style scoped>
span.dropdown-item {
  cursor: pointer;
}
.dropdown-item .bi {
  margin-right: 10px;
}
</style>